import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import ClientConfig from '../../../config/clientConfig';
import { IApplicationState } from '../../../store';
import { PrimaryButton, TextField } from '@fluentui/react'
import { IConfigDataState } from '../../../store/ConfigData/types';
import { setCustomerCount, setCustomerData, setCustomerPurpose } from '../../../store/CustomerData/action';
import { setCurrentFlow, setCurrentPage, setResponsibleEmployee, setResponsibleEmployeeName } from '../../../store/Flow/actions';
import { IFlowState } from '../../../store/Flow/types';
import { setCurrentDepartment } from '../../../store/LayoutData/actions';
import { ILayoutDataState } from '../../../store/LayoutData/types';
import { ILocalizationState } from '../../../store/Localization/types';
import KeyboardWrapper from '../../LayoutElements/VirtualKeyboard/KeyboardWrapper';
import Keyboard from "react-simple-keyboard";
import StylesUtil from '../../../utils/StylesUtil';
import FlowCard from './FlowCard';
import './index.css';
import { LocalizationDictionary } from '../../../localization/localizationDictionary';
import { IEmployeeState } from '../../../store/Employees/types';
import { ICustomerDataState } from '../../../store/CustomerData/types';
interface ILogo {
    logo: any;
}

interface IDispatchProps {
    setCurrentPage: typeof setCurrentPage;
    setCurrentFlow: typeof setCurrentFlow;
    setCustomerPurpose: typeof setCustomerPurpose;
    setCurrentDepartment: typeof setCurrentDepartment;
    setResponsibleEmployee: typeof setResponsibleEmployee;
    setResponsibleEmployeeName: typeof setResponsibleEmployeeName;
    setCustomerData: typeof setCustomerData;
    setCustomerCount: typeof setCustomerCount;
}
interface CustomAttribute {
    name: string;
    value: string;
    optional: boolean;
}
interface IState {
    customAttributes: CustomAttribute[];
    customerNameCustomAttribute: CustomAttribute;
    buttonDisabled: boolean;
    focusedValueKey: string;
    companyAttributesList: CompanyAttributeDTO[];
    searchTerm?: string;
    searchedEmployees: EmployeeDTO[];
    pickedEmployeeName: string;
    pickedEmployeeId: number;
    fieldIsFocused: boolean;
    input: string;
    barcode: string;
    lastKeyTime: number;
}

type Props = IDispatchProps & IFlowState & ILayoutDataState & IConfigDataState & ILocalizationState & ILogo & IEmployeeState & ICustomerDataState;

class FrontPage extends React.Component<Props, IState> {
    public readonly state: IState = {
        customAttributes: [],
        customerNameCustomAttribute: { name: 'customerNameCustomAttribute', value: '', optional: false },
        buttonDisabled: true,
        focusedValueKey: null,
        companyAttributesList: [],
        searchTerm: '',
        searchedEmployees: [],
        pickedEmployeeName: 'Search for employees',
        pickedEmployeeId: 0,
        fieldIsFocused: false,
        input: "",
        lastKeyTime: 0,
        barcode: ""
    }

    private readonly strings = LocalizationDictionary.getStrings;
    private readonly keyboard: React.MutableRefObject<typeof Keyboard> = React.createRef();
    private readonly quickFlowLayouts = ["Lauritzen", "Bulkers"];

    private searchFieldName = "employeeSearchFieldName"
    private hrFlowCard = false;
    private otherFlowCard = false;
    private checkinNoInfoCard = false;
    private checkinWithInfoCard = true;
    public currentslide = 1;
    public slideActive = true;
    isListeningForBarcodes: boolean = true;

    public componentDidMount = () => {
        const { language, languageData, currentFlow, layoutData } = this.props;

        // Below code is part of quick flow front page
        if (this.quickFlowLayouts.includes(layoutData.code)) {

            this.searchEmployees("");

            // Set custom attributes list
            var currentLanguageOptions = languageData.find(({ localizationCode }) => localizationCode === language);
            const attributes = currentLanguageOptions && currentLanguageOptions.checkInCustomAttributes ? currentLanguageOptions.checkInCustomAttributes : [];
            this.setState({ companyAttributesList: attributes });

            // Set custom attributes
            const customAttributes = attributes.map(a => ({ name: a.attributeName, value: '', optional: true }));
            if (currentFlow === "GroupCheckin") {
                customAttributes.push({ name: 'Antal deltagere', value: '', optional: false })
            }
            this.setState({ customAttributes });
        }

        this.setButtonState();

        this.isListeningForBarcodes = true;
        window.addEventListener('keydown', this.handleKeyDown);
    }


    componentWillUnmount() {
        this.isListeningForBarcodes = false;
        // Remove keydown event listener when the component unmounts
        window.removeEventListener('keydown', this.handleKeyDown);
    }

    handleKeyDown = (e: KeyboardEvent) => {
        const currentTime = new Date().getTime();
        const timeSinceLastKey = currentTime - this.state.lastKeyTime;

        if (timeSinceLastKey > 100) {
            // If the time between key presses is longer than 100ms, reset the barcode. Do not allow statechanges if we are progressing
            this.isListeningForBarcodes && this.setState({ barcode: '' });
        }

        // Check if the Enter key is pressed
        if (e.key === 'Enter' && this.state.barcode !== '') {
            // Trigger the action when the Enter key is pressed
            this.onBarcodeScanned(this.state.barcode);
            // Reset barcode after it's processed
            this.isListeningForBarcodes && this.setState({ barcode: '' }); // Do not allow statechanges if we are progressing
        } else {
            // Append the current key to the barcode
            this.isListeningForBarcodes && this.setState((prevState) => ({ // Do not allow statechanges if we are progressing
                barcode: prevState.barcode + e.key,
                lastKeyTime: currentTime,
            }));
        }
    };

    onBarcodeScanned = (barcode: string) => {
        const { data, setCurrentFlow, setCurrentPage } = this.props;
        this.slideActive = false;
        data.phoneNumber = barcode;
        setCustomerData(data);
        setCurrentFlow("Checkout")
        setCurrentPage("MessagePage" as PageType);
        console.log('Barcode scanned:', barcode);
        // Implement the action to take when barcode is scanned (API call, etc.)
    };

    private setButtonState = () => {
        const { buttonDisabled, customAttributes, customerNameCustomAttribute } = this.state;
        const { layoutData, configData } = this.props;

        let allFieldsFilled = true;

        // Below code is part of quick flow front page
        if (this.quickFlowLayouts.includes(layoutData.code)) {
            if (!customerNameCustomAttribute.optional && customerNameCustomAttribute.value === '') {
                allFieldsFilled = false;
            }

            if (allFieldsFilled) {
                for (var attribute of customAttributes) {
                    if (!attribute.optional && attribute.value === '') {
                        allFieldsFilled = false;
                    }
                }
            }
        }

        if (buttonDisabled === allFieldsFilled) {
            this.setState({ buttonDisabled: !allFieldsFilled });
        }
    }

    private onAttributeChange = (newAttributeValue: string, attributeName: string) => {
        const { customAttributes, customerNameCustomAttribute } = this.state;
        const { layoutData } = this.props;
        this.setState({ input: newAttributeValue })
        // Below code is part of quick flow front page
        if (this.quickFlowLayouts.includes(layoutData.code)) {

            if (this.keyboard.current) {
                (this.keyboard.current as any).setInput(newAttributeValue ?? "");
            }

            if (this.searchFieldName === attributeName) {
                this.searchEmployees(newAttributeValue);
                this.setState({ searchTerm: newAttributeValue });
            }
            else if (customerNameCustomAttribute.name === attributeName) {
                customerNameCustomAttribute.value = newAttributeValue;
                this.setState({ customerNameCustomAttribute });
            }
            else {
                customAttributes.filter(a => a.name === attributeName)[0].value = newAttributeValue;
                this.setState({ customAttributes });
            }
        }
        this.setButtonState();
    }

    private onFieldFocus = (key: string) => {
        this.setState({ fieldIsFocused: true })

        const { customAttributes, customerNameCustomAttribute, searchTerm } = this.state;
        const { layoutData } = this.props;

        // Below code is part of quick flow front page
        if (this.quickFlowLayouts.includes(layoutData.code)) {
            if (this.searchFieldName === key) {
                const prevInput = searchTerm;
                if (this.keyboard.current) {
                    (this.keyboard.current as any).setInput(prevInput ?? "");
                }
            } else if (customerNameCustomAttribute.name === key) {
                const prevInput = customerNameCustomAttribute.value;
                if (this.keyboard.current) {
                    (this.keyboard.current as any).setInput(prevInput ?? "");
                }
            } else {
                const prevInput = customAttributes.filter(a => a.name === key)[0].value;
                if (this.keyboard.current) {
                    (this.keyboard.current as any).setInput(prevInput ?? "");
                }
            }
            this.setState({ focusedValueKey: key });
        }
    }

    private onFieldBlur = () => {
        this.setState({ fieldIsFocused: false })
    }

    private onKeyboardInputChange = (input: string) => {
        const { focusedValueKey, customAttributes, customerNameCustomAttribute } = this.state;
        const { layoutData } = this.props;
        this.setState({input})
        // Below code is part of quick flow front page
        if (this.quickFlowLayouts.includes(layoutData.code)) {
            if (this.searchFieldName === focusedValueKey) {
                this.searchEmployees(input);
                this.setState({ searchTerm: input });
            }
            if (customerNameCustomAttribute.name === focusedValueKey) {
                customerNameCustomAttribute.value = input;
                this.setState({ customerNameCustomAttribute });
            }
            else {
                const attr = customAttributes.filter(a => a.name === focusedValueKey)[0]
                if (attr) {
                    attr.value = input
                    this.setState({ customAttributes });
                }
            }
        }
        console.log(input)
        this.setButtonState();
    }
    private searchEmployees = async (search: string) => {
        const { employees } = this.props
        // Below code is part of quick flow front page
        try {
            var filteredEmployees: EmployeeDTO[] = [];
            search = search.toLowerCase();
            employees.forEach((item) => {
                if (search === "" || (item.firstName + " " + item.secondName).toLowerCase().includes(search)) {
                    filteredEmployees.push(item);
                }
            });
            this.setState({ searchedEmployees: filteredEmployees })
        }
        catch (error) {
            console.log("Issue searching for employees.");
        }
    }

    private employeeClicked = (id: number, name: string) => {
        const { setResponsibleEmployee, setResponsibleEmployeeName } = this.props;

        // Below code is part of quick flow front page
        setResponsibleEmployee(id);
        setResponsibleEmployeeName(name);
        this.setState({ pickedEmployeeName: name, pickedEmployeeId: id, searchTerm: name });
    }

    private flowCardClick = (type: FlowType, purpose: string) => {
        const { setCurrentFlow, setCurrentPage, layoutData, conditionData, configData, departmentsData, language, setCustomerPurpose, setCurrentDepartment, setCustomerData, setCustomerCount, data } = this.props;
        const { customAttributes, companyAttributesList, customerNameCustomAttribute, pickedEmployeeId } = this.state;
        if (this.quickFlowLayouts.includes(layoutData.code)) {
            // Below code is part of quick flow front pagevar firstname
            var name = customerNameCustomAttribute.value;
            const numberofnames = name.split(' ');
            var firstname;
            var lastname;
            for (var i = 0; i < numberofnames.length; i++) {
                if (i === 0) {
                    firstname = numberofnames[i].charAt(0).toUpperCase() + numberofnames[i].slice(1).toLowerCase();
                } else if (i === 1) {
                    lastname = numberofnames[i].charAt(0).toUpperCase() + numberofnames[i].slice(1).toLowerCase();
                } else {
                    lastname += " " + numberofnames[i].charAt(0).toUpperCase() + numberofnames[i].slice(1).toLowerCase();
                }
            }

            data.firstName = firstname;
            data.secondName = lastname;
            data.customerInfos = this.mapAttributesToDTO(customAttributes, companyAttributesList);

            setCustomerData(data);
            setCustomerCount(1);
            if (pickedEmployeeId > 0) {
                type = "CheckinAppointment"
            } else {
                type = "CheckinNoAppointment"
            }
        }

        this.slideActive = false;
        var currentCondition = conditionData.find(({ localizationCode }) => localizationCode === language);
        console.log(type)
        setCurrentFlow(type);
        setCustomerPurpose(purpose);

        if (layoutData.code === "SociatyOfLife" || layoutData.code === "OfficePartner") {
            let background = document.getElementsByClassName("ultra-turbo-container") as HTMLCollectionOf<HTMLElement>;
            background[0].style.backgroundImage = "";
        }

        switch (type) {
            case "CheckinAppointment":
                if (this.quickFlowLayouts.includes(layoutData.code)) {
                    // Below code is part of quick flow front page
                    setCurrentPage("MessagePage" as PageType)
                    break;
                } else if (currentCondition && currentCondition.useCondition && currentCondition.condition !== null && currentCondition.condition.trim() !== "") {
                    setCurrentPage("ConditionPage" as PageType)
                    break;
                } else if (currentCondition && currentCondition.usePdf && currentCondition.pdfURL !== null && currentCondition.pdfURL.trim() !== "") {
                    setCurrentPage("ConditionPdfPage" as PageType)
                    break;
                } else if (layoutData.code === "EDForhandler" || layoutData.code === "Reisswolf") {
                    setCurrentPage("MainFieldsPage" as PageType)
                    break;
                } else if (layoutData.code === "HIN") {
                    if (configData.useClientDepartments && departmentsData && departmentsData.length > 0) {
                        if (departmentsData.length === 1) {
                            setCurrentDepartment(0);
                            setCurrentPage("EmployeePage" as PageType);
                        } else {
                            setCurrentPage("DepartmentPage" as PageType);
                        }
                    } else {
                        setCurrentPage("EmployeePage" as PageType);
                    }
                    break;
                } else if (layoutData.code === "Heimdalls" || layoutData.code === "BirchEjendomme") {
                    setCurrentPage("MainFieldsPage" as PageType)
                    break;
                }
                else if (layoutData.code === "Meneta") {
                    setCurrentPage("ConditionPdfPage")
                    break;
                } else if (layoutData.code === "TotalEnergies") {
                    setCurrentPage("VisitorInformationInputsPage" as PageType)
                    break;
                }
                else {
                    setCurrentPage("PhoneNumberPage" as PageType)
                    break;
                }
            case "CheckinNoAppointment":
                if (this.quickFlowLayouts.includes(layoutData.code)) {
                    // Below code is part of quick flow front page
                    setCurrentPage("MessagePage" as PageType)
                    break;
                } else if (layoutData.code === "Flensted" || layoutData.code === "LouisPoulsen" || layoutData.code === "Haugen" || layoutData.code === "Desmi" || layoutData.code === "FibiaHaslev" || layoutData.code === "FibiaAndel" || layoutData.code === "BirchEjendomme") {
                    setCurrentPage("MessagePage" as PageType);
                    break;
                } else if (layoutData.code === "Meneta") {
                    setCurrentPage("MessagePage")
                    break;
                } else if (currentCondition && currentCondition.useCondition && currentCondition.condition !== null && currentCondition.condition.trim() !== "") {
                    setCurrentPage("ConditionPage" as PageType)
                    break;
                } else if (currentCondition && currentCondition.usePdf && currentCondition.pdfURL !== null && currentCondition.pdfURL.trim() !== "") {
                    setCurrentPage("ConditionPdfPage" as PageType)
                    break;
                } else if (layoutData.code === "EDForhandler" || layoutData.code === "Sef" || layoutData.code === "Seftest" || layoutData.code === "ErhvershusMidt" || layoutData.code === "HIN") {
                    if (currentCondition && currentCondition.useVideo && currentCondition.videoURL !== null && currentCondition.videoURL.trim() !== "") {
                        setCurrentPage("ConditionVideoPage" as PageType);
                        break;
                    } else {
                        setCurrentPage("MessagePage" as PageType);
                        break;
                    }
                }
                else {
                    setCurrentPage("PhoneNumberPage" as PageType);
                    break;
                }
            case "Checkout":
                if (layoutData.code === "Reisswolf") {
                    //Add new page to view currently checked in
                    setCurrentPage("CheckoutListPage" as PageType);
                    break;
                } else {
                    setCurrentPage("PhoneNumberPage" as PageType);
                    break;
                }
            case "Carrier":
                setCurrentPage("MessagePage" as PageType);
                break;
            case "HR":
                setCurrentPage("MessagePage" as PageType);
                break;
            case "Other":
                if (layoutData.code === "Meneta") {
                    setCurrentPage("ConditionPdfPage")
                    break;
                }
                setCurrentPage("MessagePage" as PageType);
                break;
            case "CheckinNoInfo":
                if (configData.useClientDepartments && departmentsData && departmentsData.length > 0) {
                    if (departmentsData.length === 1) {
                        setCurrentDepartment(0);
                        setCurrentPage("EmployeePage" as PageType);
                    } else {
                        setCurrentPage("DepartmentPage" as PageType);
                    }
                } else {
                    setCurrentPage("EmployeePage" as PageType);
                }
                break;
            case "GroupCheckin":
                if (currentCondition && currentCondition.useCondition && currentCondition.condition !== null && currentCondition.condition.trim() !== "") {
                    setCurrentPage("ConditionPage" as PageType)
                    break;
                } else if (currentCondition && currentCondition.usePdf && currentCondition.pdfURL !== null && currentCondition.pdfURL.trim() !== "") {
                    setCurrentPage("ConditionPdfPage" as PageType)
                    break;
                } else {
                    setCurrentPage("PhoneNumberPage" as PageType)
                }
                break;
            case "LeverandoerCheckin":
                setCurrentPage("MainFieldsPage" as PageType)
                break;
            case "Event":
                setCurrentPage("MainFieldsPage" as PageType)
                break;
            case "Carpenter":
                if (layoutData.code === "Meneta") {
                    setCurrentPage("ConditionPdfPage" as PageType)
                }
                if (currentCondition && currentCondition.useCondition && currentCondition.condition !== null && currentCondition.condition.trim() !== "") {
                    setCurrentPage("ConditionPage" as PageType)
                    break;
                }
                break;
        }
    }

    private styleFrontPageContainer(): React.CSSProperties {
        const { layoutData } = this.props
        if (layoutData.code === "EDForhandler") {
            return ({ width: 1500, height: 400, position: 'absolute', top: '400px', left: '0px' })
        } else if (layoutData.code === "OfficePartner") {
            return ({ width: 1080 })
        } else if (layoutData.code === "FibiaHaslevHouse" || layoutData.code === "Meneta" || layoutData.code === "DinexTMLHouse" || layoutData.code === "BirchEjendomme") {
            //return ({ width: 'auto', height: 'auto', display: 'flex', justifyContent: 'space-around' })
            return ({ width: 'auto', height: 'auto' })
        } else {
            return ({ width: 1500, height: 400 })
        }
    }

    private mapAttributesToDTO = (attributeValues: CustomAttribute[], attributes: CompanyAttributeDTO[]): CustomerInfoDTO[] => {
        const infos: CustomerInfoDTO[] = [];
        // Below code is part of quick flow front page
        attributes.forEach(att => {
            infos.push(
                ({
                    companyAttributeId: att.id,
                    attributeValue: attributeValues.filter(av => av.name === att.attributeName)[0].value
                }));
        })
        return infos;
    }

    private addNextButton(buttonDisabled: boolean) {
        const { layoutData } = this.props;

        // Below code is part of quick flow front page
        return (<div className="custom-fields-button-container" style={{
            height: 'auto', width: this.state.searchTerm.length && this.state.searchedEmployees.length ? '18%' : '100%', margin: this.state.searchTerm.length && this.state.searchedEmployees.length ? 'none' : '10px 0'
        }}>
            <PrimaryButton className="custom-fields-button" styles={StylesUtil.getButtonStyles(layoutData)} text={this.strings().checkinreisswolf} onClick={() => this.flowCardClick("CheckinAppointment", this.strings().purposeEmpty)} disabled={buttonDisabled} allowDisabledFocus />
        </div>)
    }

    private quickFlowContainer() {
        const { customAttributes, customerNameCustomAttribute, searchTerm, searchedEmployees, pickedEmployeeName, pickedEmployeeId } = this.state;
        const { layoutData } = this.props
        // Below code is part of quick flow front page
            return (<div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={StylesUtil.getCustomTextFieldsContainerStyle(layoutData)}>
                    {customerNameCustomAttribute ?
                        (<TextField
                            className="custom-text-field"
                            styles={StylesUtil.getTextFieldStyles(layoutData, false)}
                            key={customerNameCustomAttribute.name}
                            placeholder={"Name" + (customerNameCustomAttribute.optional ? "(optional)" : "")}
                            value={customerNameCustomAttribute.value}
                            onChange={(e, v) => this.onAttributeChange(v, customerNameCustomAttribute.name)}
                            onFocus={() => this.onFieldFocus(customerNameCustomAttribute.name)} underlined={ClientConfig.layoutVersion === 'v1' ? false : true}
                            onBlur={() => this.onFieldBlur()}
                        />) //autofocus
                        : null}
                    {customAttributes.map((att) => {
                        return (<TextField
                            className="custom-text-field"
                            styles={StylesUtil.getTextFieldStyles(layoutData, false)}
                            key={att.name}
                            placeholder={att.name + (att.optional ? " (optional)" : "")}
                            value={att.value}
                            onChange={(e, v) => this.onAttributeChange(v, att.name)}
                            onFocus={() => this.onFieldFocus(att.name)}
                            onBlur={() => this.onFieldBlur()}
                            underlined={ClientConfig.layoutVersion === 'v1' ? false : true}
                        />)
                    })}
                    <TextField
                        className="custom-text-field"
                        styles={StylesUtil.getTextFieldStyles(layoutData, pickedEmployeeName !== "Search for employees")}
                        key={this.searchFieldName}
                        placeholder="Search for employees"
                        value={searchTerm ?? ""}
                        onChange={(e, v) => this.onAttributeChange(v, this.searchFieldName)}
                        onFocus={() => this.onFieldFocus(this.searchFieldName)}
                        onBlur={() => this.onFieldBlur()}
                        underlined={ClientConfig.layoutVersion === 'v1' ? false : true}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {!!searchTerm.length && !!searchedEmployees.length &&
                        <div style={{ ...StylesUtil.getCustomTextFieldsContainerStyle(layoutData) }}>
                            <ul style={{ maxHeight: '142px', overflowY: 'auto', listStyleType: 'none', padding: 0, margin: '20px 30px 15px 30px', border: '1px solid black' }}>
                                {searchedEmployees && searchedEmployees.map((employee, index) => (
                                    <li key={index} style={StylesUtil.simpleEmployeeList(layoutData, pickedEmployeeId === employee.id)} onClick={() => this.employeeClicked(employee.id, employee.firstName + " " + employee.secondName)}>{employee.firstName + " " + employee.secondName}</li>
                                ))}
                            </ul>
                        </div>
                    }
                    {this.addNextButton(!customerNameCustomAttribute.value.length || !pickedEmployeeId)}
                </div>
            </div>)
    }

    private addKeyboard() {
        const { layoutData } = this.props;
        // Below code is part of quick flow front page
        if (this.quickFlowLayouts.includes(layoutData.code)) {
            return (
                <div style={{}}
                    onMouseDown={(e) => {
                        e.preventDefault()
                    }}>
                    <KeyboardWrapper
                        keyboardRef={this.keyboard}
                        onChange={this.onKeyboardInputChange} input={this.state.input} />
                </div>
            )
        }
    }

    private frontPageContainer() {
        const { layoutData, layoutsSharedHome, layoutsRedirect, setCurrentFlow, setCurrentPage } = this.props

        if (layoutData.code === "FibiaMuseum") {
            setCurrentPage('PhoneNumberPage' as PageType)
            setCurrentFlow('CheckinAppointment' as FlowType)
        }

        if (layoutData.code === "LouisPoulsen") {
            return (<div className="front-page-container" style={this.styleFrontPageContainer()}>
                <div style={{ width: '275px', height: '162px', margin: '60px' }}><FlowCard type={"Guest"} cardKey={"CheckinAppointment" as FlowType} click={this.flowCardClick} /></div>
                <div>
                    <div style={{ width: '275px', height: '162px', margin: '60px' }}><FlowCard type={"Carpenter"} cardKey={"CheckinAppointment" as FlowType} click={this.flowCardClick} /></div>
                    {ClientConfig.flow.checkoutEnabled ? <div style={{ width: '275px', height: '100px', margin: '120px 60px 60px 60px', }}><FlowCard type={"Checkout"} cardKey={"Checkout" as FlowType} click={this.flowCardClick} /></div> : null}
                </div>
                {ClientConfig.flow.doesNotHaveAppointmentEnabled ? <div style={{ width: '275px', height: '162px', margin: '60px' }}><FlowCard type={"NoAppointment"} cardKey={"CheckinNoAppointment" as FlowType} click={this.flowCardClick} /></div> : null}
            </div>)
        }

        if (layoutData.code === "FibiaHaslevHouse") {
            return (<div className="front-page-container" style={this.styleFrontPageContainer()}>
                {
                    layoutsSharedHome.config.has(layoutData.code)
                        //? <div style={{ backgroundColor: 'transparent', width: 1500, height: 390, position: 'absolute', left: 190, top: 350, display: 'flex' }}>
                        ? <div style={{ backgroundColor: 'transparent', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                            {layoutsSharedHome.config.get(layoutData.code)
                                .map(otherCode => layoutsRedirect.config.has(otherCode) ?
                                    layoutsRedirect.config.get(otherCode).layout :
                                    <div></div>)}
                            {
                                //changed that companyid is optional for flowcard
                                ClientConfig.flow.checkoutEnabled
                                    ? <FlowCard type={"Checkout"} cardKey={"Checkout" as FlowType} click={this.flowCardClick} />
                                    : null
                            }
                        </div>
                        : null
                }
            </div>)
        }

        return (<div className="front-page-container" style={this.styleFrontPageContainer()}>
            {
                this.checkinWithInfoCard && layoutData.code !== "Retograad"
                    ? <FlowCard type={"Appointment"} cardKey={"CheckinAppointment" as FlowType} click={this.flowCardClick} />
                    : null
                // add flowcard HR
            }
            {
                this.checkinNoInfoCard && layoutData.code === "ErhvershusMidt"
                    ? <FlowCard type={"CheckinNoInfo"} cardKey={"CheckinNoInfo" as FlowType} click={this.flowCardClick} />
                    : null
            }
            {
                ClientConfig.flow.doesNotHaveAppointmentEnabled && layoutData.code !== "Retograad" && layoutData.code !== "Visionhouse" && layoutData.code !== "VismaHouse" && layoutData.code !== "SSIDiagnosticaHouse" && layoutData.code !== "CSolutionHouse" && layoutData.code !== "FibiaHaslevHouse" && layoutData.code !== "LauritzenHouse" && layoutData.code !== "DinexTMLHouse"
                    ? <FlowCard type={"NoAppointment"} cardKey={"CheckinNoAppointment" as FlowType} click={this.flowCardClick} />
                    : null
            }
            {
                layoutData.code === "Flensted"
                    ? <div style={StylesUtil.getFlowCardContainerStyle(layoutData)}>
                        <FlowCard type={"GroupCheckin"} cardKey={"GroupCheckin" as FlowType} click={this.flowCardClick} />
                    </div>
                    : null
            }
            {
                layoutData.code === "Seftest" || layoutData.code === "Sef"
                    ? <FlowCard type={"GroupCheckin"} cardKey={"GroupCheckin" as FlowType} click={this.flowCardClick} />
                    : null
            }
            {
                layoutData.code === "Meneta" ?
                    <FlowCard type={"Carpenter"} cardKey={"Carpenter" as FlowType} click={this.flowCardClick} />
                    : null
            }
            {
                //give this an ekstra value with it to see id of the company
                ClientConfig.flow.carrierFlowEnabled
                    ? <FlowCard type={"Carrier"} cardKey={"Carrier" as FlowType} click={this.flowCardClick} companyId={layoutData.companyId} />
                    : null
            }
            {
                //changed that companyid is optional for flowcard
                ClientConfig.flow.checkoutEnabled
                    ? <FlowCard type={"Checkout"} cardKey={"Checkout" as FlowType} click={this.flowCardClick} />
                    : null
            }
            {
                this.hrFlowCard
                    ? <FlowCard type={"HR"} cardKey={"HR" as FlowType} click={this.flowCardClick} />
                    : null
                // add flowcard HR
            }
            {
                this.otherFlowCard
                    ? <FlowCard type={"Other"} cardKey={"Other" as FlowType} click={this.flowCardClick} />
                    : null
            }
            {
                layoutsSharedHome.config.has(layoutData.code)
                    ? <div style={{ backgroundColor: 'transparent', width: 1500, height: 390, position: 'absolute', left: 190, top: 350 }}>
                        {layoutsSharedHome.config.get(layoutData.code)
                            .map(otherCode => layoutsRedirect.config.has(otherCode) ?
                                layoutsRedirect.config.get(otherCode).layout :
                                <div></div>)}
                    </div>
                    : null
            }
            {
                layoutData.code === "LauritzenHouse"
                    ? <div style={StylesUtil.getFlowCardContainerStyle(layoutData)}>
                        <div>Delivery: Please call +45 3396 8446</div>
                    </div>
                    : null
            }
            {
                ClientConfig.flow.doesNotHaveAppointmentEnabled && (layoutData.code === "Visionhouse" || layoutData.code === "VismaHouse" || layoutData.code === "SSIDiagnosticaHouse" || layoutData.code === "CSolutionHouse" || layoutData.code === "FibiaHaslevHouse" || layoutData.code === "LauritzenHouse" || layoutData.code === "DinexTMLHouse")
                    ? <div style={{ position: 'absolute', top: 730 }}><FlowCard type={"NoAppointment"} cardKey={"CheckinNoAppointment" as FlowType} click={this.flowCardClick} /></div>
                    : null
            }
            {
                layoutData.code === "Comm2ig"
                    ? <FlowCard type={"LeverandoerCheckin"} cardKey={"LeverandoerCheckin" as FlowType} click={this.flowCardClick} />
                    : null
            }
            {
                layoutData.code === "EDForhandler"
                    ? <FlowCard type={"Event"} cardKey={"Event" as FlowType} click={this.flowCardClick} />
                    : null
            }
        </div>)
    }

    private testContainer() {
        const { layoutData, language, languageData } = this.props
        var currentLanguageOptions = languageData.find(({ localizationCode }) => localizationCode === language);
        //console.log((getCompanyId() == CompanyId.retograad ? "data her" : "ingen data her")
        if (layoutData.code === "Retograad") {
            return (<div className="front-page-container" style={this.styleFrontPageContainer()}>
                {
                    <div style={{ backgroundColor: ClientConfig.secondaryColor, height: '585px', width: '915px', position: 'absolute', top: '465px', left: '30px' }}>
                        <h2 style={{ fontWeight: 'bolder', position: 'relative', top: '40px', textAlign: 'center' }} >Med aftale</h2>
                        <h3 style={{ position: 'relative', top: '70px', textAlign: 'center', paddingLeft: '50px', paddingRight: '50px' }}>{currentLanguageOptions && currentLanguageOptions.appointmentText ? currentLanguageOptions.appointmentText : ''}</h3>
                        <div style={{ position: 'absolute', top: '350px', left: '355px' }} >
                            <FlowCard type={"CheckinNoInfo"} cardKey={"CheckinNoInfo" as FlowType} click={this.flowCardClick} />
                        </div>
                    </div>

                }
                {
                    <div style={{ backgroundColor: ClientConfig.secondaryColor, height: '585px', width: '915px', position: 'absolute', top: '465px', right: '30px' }}>
                        <h2 style={{ fontWeight: 'bolder', position: 'relative', top: '40px', textAlign: 'center' }}>Møde uden aftale</h2>
                        <h3 style={{ position: 'relative', top: '70px', textAlign: 'center', paddingLeft: '50px', paddingRight: '50px' }} >{currentLanguageOptions && currentLanguageOptions.doesNotHaveAnAppointmentText ? currentLanguageOptions.doesNotHaveAnAppointmentText : ''}</h3>
                    </div>

                }
            </div>)
        } else {
            return null
        }
    }

    // TODO: Special logo case
    private addBigLogo() {
        const { logo, layoutData } = this.props
        if (layoutData.code === "Reisswolf") {
            return (<img src={logo} style={{ position: 'absolute', width: 640, height: 640, top: 0, left: 640 }} alt="Reisswolf logo" />)
        }
    }

    render() {
        const { currentPage, layoutData } = this.props;
        if (ClientConfig.layoutVersion === 'v3' && layoutData.code === "SociatyOfLife") {
            this.hrFlowCard = true;
            this.otherFlowCard = true;
        } else if (ClientConfig.layoutVersion === 'v3' && layoutData.code === "Retograad") {
            //console.log("do we enter 66 ??????");
            this.checkinNoInfoCard = true;
            this.checkinWithInfoCard = false;
        } else if (layoutData.code === "Visionhouse" || layoutData.code === "VismaHouse" || layoutData.code === "SSIDiagnosticaHouse" || layoutData.code === "CSolutionHouse" || layoutData.code === "FibiaHaslevHouse" || layoutData.code === "LauritzenHouse" || layoutData.code === "DinexTMLHouse") {
            this.checkinWithInfoCard = false;
        } else if (layoutData.code === "ErhvershusMidt") {
            this.checkinWithInfoCard = false
            this.checkinNoInfoCard = true
        } else if (layoutData.code === "Meneta") {
            this.hrFlowCard = false;
            this.otherFlowCard = true;
            this.checkinNoInfoCard = false;
            this.checkinWithInfoCard = true;
            //console.log("do we enter 66 else??????");
        } else {
            this.hrFlowCard = false;
            this.otherFlowCard = false;
            this.checkinNoInfoCard = false;
            this.checkinWithInfoCard = true;
            //console.log("do we enter 66 else??????");
        }
        if (currentPage === "FrontPage") {
            // Below code is part of quick flow front page
            if (this.quickFlowLayouts.includes(layoutData.code)) {
                return (
                    <div className="custom-fields-container">
                        {this.addBigLogo()}
                        {this.quickFlowContainer()}
                        {this.state.fieldIsFocused && this.addKeyboard()}
                    </div>
                );
            } else {
                // Normal front page
                return (
                    <div>
                        {this.addBigLogo()}
                        {this.frontPageContainer()}
                        {this.testContainer()}
                    </div>
                );
            }
        }
        else {
            return (<div>Error</div>);
        }
    }
}

const mapStateToProps = (state: IApplicationState): Partial<IFlowState & ILayoutDataState & IConfigDataState & ILocalizationState & IEmployeeState & ICustomerDataState> => {
    const { currentPage, currentFlow } = state.flow;
    const { layoutData, layoutsSharedHome, layoutsRedirect, conditionData, departmentsData } = state.layoutData
    const { configData } = state.configData;
    const { language } = state.localization;
    const { languageData } = state.configData;
    const { employees } = state.employeeData
    const { data } = state.customerData;
    return {
        currentPage, currentFlow, layoutData, layoutsSharedHome, layoutsRedirect, configData, conditionData, departmentsData, language, languageData, employees, data
    };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
    return bindActionCreators(
        { setCurrentPage, setCurrentFlow, setCustomerData, setCustomerCount, setCustomerPurpose, setCurrentDepartment, setResponsibleEmployee, setResponsibleEmployeeName },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FrontPage);