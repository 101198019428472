// Interfaces
import { IFlowState } from '../../../store/Flow/types';
import { ICustomerDataState } from '../../../store/CustomerData/types';
import { ILocalizationState } from '../../../store/Localization/types';
import { ILayoutDataState, KeyboardLayoutDesign } from '../../../store/LayoutData/types';
import { IConfigDataState } from '../../../store/ConfigData/types';
import { IApplicationState } from '../../../store';

// Types
import { resetStoreState, setCurrentFlow, setCurrentPage, setPhoneNumberExists } from '../../../store/Flow/actions';
import { setCustomerData } from '../../../store/CustomerData/action';
import { setCurrentDepartment } from '../../../store/LayoutData/actions';
import Keyboard from 'react-simple-keyboard/build/components/Keyboard';

// Modules
import * as React from 'react';
import { ITextField, PrimaryButton, TextField } from '@fluentui/react'
import StylesUtil from '../../../utils/StylesUtil';
import KeyboardWrapper from '../../LayoutElements/VirtualKeyboard/KeyboardWrapper';
import { createRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { LocalizationDictionary } from '../../../localization/localizationDictionary';

// CSS
import './index.css'

interface IState {
    customerData: CustomerDTO
    fullVisitorName: string;
    phoneNumber: string;
    companyName: string;
    input: string;
    focusedField: React.RefObject<ITextField> | null;
    keyboardLayout: KeyboardLayoutType;
    keyboardLayoutDesign: KeyboardLayoutDesign;
    canClickNext: boolean;
    multiFieldInputName: string;
}
interface IDispatchProps {
    setCurrentPage: typeof setCurrentPage;
    setCurrentFlow: typeof setCurrentFlow;
    setPhoneNumberExists: typeof setPhoneNumberExists;
    setCustomerData: typeof setCustomerData;
    resetStoreState: typeof resetStoreState;
    setCurrentDepartment: typeof setCurrentDepartment;
}

type Props = IDispatchProps & IFlowState & ICustomerDataState & ILocalizationState & ILayoutDataState & IConfigDataState;
class VisitorInformationInputsPage extends React.Component<Props, IState> {

    private readonly strings = LocalizationDictionary.getStrings;
    private readonly keyboard: React.MutableRefObject<typeof Keyboard> = React.createRef();
    private nameRef = createRef<ITextField>();
    private phoneNumberRef = createRef<ITextField>();
    private companyNameRef = createRef<ITextField>();

    constructor(props: Props) {
        super(props)
        this.state = {
            customerData: this.props.data,
            companyName: '',
            fullVisitorName: '',
            phoneNumber: '',
            input: '',
            focusedField: null,
            keyboardLayout: 'upperCaseAlphabet',
            canClickNext: false,
            multiFieldInputName: '',
            keyboardLayoutDesign: KeyboardLayoutDesign.BIG
        }
    }

    componentDidMount() {
        //Focus on name
        this.onFieldFocus(this.nameRef, this.strings().visitorNameInputLabel)
    }

    // Handler for changes from the KeyboardWrapper
    onKeyboardInputChange = (input: string) => {
        const { focusedField } = this.state;

        // Only update the field that is currently focused
        if (focusedField === this.nameRef) {
            this.setState({ fullVisitorName: input });
        }
        else if (focusedField === this.phoneNumberRef) {
            this.setState({ phoneNumber: input });
        }
        else if (focusedField === this.companyNameRef) {
            this.setState({ companyName: input });
        }
        this.setState({ input, canClickNext: this.checkIfCanContinue() })
    };

    onInputChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, input?: string) => {
        var newValue = input;
        if (this.state.focusedField === this.phoneNumberRef) {
            newValue = newValue.replace(/[^0-9+]/g, ''); // Remove everything but numbers and '+'
            newValue = newValue.replace(/(?<=.)\+/g, ''); // Remove all '+' except the first one
        }
        this.onKeyboardInputChange(newValue)
    }

    checkIfCanContinue = (): boolean => {
        //Check valid phone number, 
        var phoneNumberMinimumLength = 8;
        const regexPattern = /^\+?\d+$/; // Allow optional leading + and numbers only
        const validNumber = this.state.phoneNumber.length >= phoneNumberMinimumLength && regexPattern.test(this.state.phoneNumber)

        //Check valid company name
        const validCompanyName = this.state.companyName && this.state.companyName.length > 0

        //Check valid person name. Must have first and surname(s)
        const validPersonName = this.state.fullVisitorName.split(' ').length > 1 && this.state.fullVisitorName.split(' ').every(n => n.length !== 0)
        return validNumber && validCompanyName && validPersonName
    }

    onFieldFocus = (fieldRef: React.RefObject<ITextField>, fieldName: string) => {
        //New input field was focused, obtain its value and update state accordingly, which will be reflected to keyboard component
        if (fieldRef === this.state.focusedField)
            return; // Don't mess with keyboard swapping while texting in the same field

        let keyboardLayout: KeyboardLayoutType = this.state.focusedField ? 'default' : 'upperCaseAlphabet';
        let input = '';
        let keyboardLayoutDesign = KeyboardLayoutDesign.BIG

        if (fieldRef === this.phoneNumberRef) {
            keyboardLayout = 'numpad';
            keyboardLayoutDesign = KeyboardLayoutDesign.NUMPAD;
            input = this.state.phoneNumber;
        } else if (fieldRef === this.nameRef) {
            input = this.state.fullVisitorName;
        } else if (fieldRef === this.companyNameRef) {
            input = this.state.companyName;
        }

        this.setState({
            focusedField: fieldRef,
            input,
            keyboardLayout,
            multiFieldInputName: fieldName,
            keyboardLayoutDesign
        });
    };

    onNextButtonClicked = async () => {
        const { data, setCustomerData, setCurrentPage } = this.props;
        const namesSplit = this.state.fullVisitorName.split(' ')
        data.firstName = namesSplit.shift()
        data.secondName = namesSplit.join(' ')
        data.phoneNumber = this.state.phoneNumber
        data.companyName = this.state.companyName
        setCustomerData(data)
        let nextPageType: PageType = "EmployeePage"
        setCurrentPage(nextPageType)
    }

    render() {
        return (
            <div className='main-fields-container inputs-container'>
                <div className='input-fields-and-next-button-container'>
                    <TextField
                        autoFocus
                        styles={StylesUtil.getTextFieldStyles({ code: this.props.layoutData.code, companyId: 0 }, false)}
                        label={this.strings().visitorNameInputLabel}
                        value={this.state.fullVisitorName}
                        onChange={this.onInputChange}
                        componentRef={this.nameRef}
                        onFocus={() => this.onFieldFocus(this.nameRef, this.strings().visitorNameInputLabel)}
                        placeholder='Enter your name'
                        autoComplete='off'
                    />
                    <TextField
                        styles={StylesUtil.getTextFieldStyles({ code: this.props.layoutData.code, companyId: 0 }, false)}
                        label={this.strings().visitorPhoneNumberInputLabel}
                        value={this.state.phoneNumber}
                        onChange={this.onInputChange}
                        componentRef={this.phoneNumberRef}
                        onFocus={() => this.onFieldFocus(this.phoneNumberRef, this.strings().visitorPhoneNumberInputLabel)}
                        placeholder='Enter your phone number'
                        autoComplete='off'
                    />
                    <TextField
                        styles={StylesUtil.getTextFieldStyles({ code: this.props.layoutData.code, companyId: 0 }, false)}
                        label={this.strings().visitorCompanyNameInputLabel}
                        value={this.state.companyName}
                        onChange={this.onInputChange}
                        componentRef={this.companyNameRef}
                        onFocus={() => this.onFieldFocus(this.companyNameRef, this.strings().visitorCompanyNameInputLabel)}
                        placeholder='Enter your company name'
                        autoComplete='off'
                    />
                    <PrimaryButton
                        styles={StylesUtil.getButtonStyles({ code: this.props.layoutData.code, companyId: 0 })}
                        className='visitor-information-next-button'
                        text={this.strings().next}
                        onClick={this.onNextButtonClicked}
                        disabled={!this.state.canClickNext}
                    />
                </div>
                <div style={{ margin: '30px' }}></div>
                <KeyboardWrapper
                    keyboardRef={this.keyboard}
                    onChange={this.onKeyboardInputChange}
                    input={this.state.input}
                    layoutDesign={this.state.keyboardLayoutDesign}
                    layoutName={this.state.keyboardLayout}
                    multiFieldInputName={this.state.multiFieldInputName}
                />
            </div>
        )
    }
}

const mapStateToProps = (state: IApplicationState): Partial<IFlowState & ICustomerDataState & ILocalizationState & ILayoutDataState & IConfigDataState> => {
    const { currentPage, currentFlow } = state.flow;
    const { data } = state.customerData;
    const { language } = state.localization;
    const { layoutData, conditionData, departmentsData } = state.layoutData;
    const { configData, languageData } = state.configData;

    return {
        currentPage, currentFlow, data, language, layoutData, conditionData, configData, departmentsData, languageData
    };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
    return bindActionCreators(
        { setCurrentPage, setCurrentFlow, setPhoneNumberExists, setCustomerData, resetStoreState, setCurrentDepartment },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VisitorInformationInputsPage);