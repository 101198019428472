import * as React from 'react';
import { connect } from 'react-redux';

import ClientConfig from '../../../config/clientConfig'
import { LocalizationDictionary } from '../../../localization/localizationDictionary'
import { IApplicationState } from '../../../store';
import { ILayoutDataState } from '../../../store/LayoutData/types';
import { ILocalizationState } from '../../../store/Localization/types';
import StylesUtil from '../../../utils/StylesUtil';

import './index.css'

type KeyType = number | string;

interface IProps {
    employeeKey: KeyType;
    employee: any;
    click: (key: KeyType, employeeName: string) => void;
    customImage?: string | undefined;
}

type Props = IProps & ILocalizationState & ILayoutDataState;

class EmployeeCard extends React.Component<Props> {

    private readonly strings = LocalizationDictionary.getStrings;

    private getIconPath = (): string => {
        const { customImage } = this.props;

        if (customImage && customImage.trim()) {
            return customImage;
        }
        else {
            return window.location.origin + "/images/person.png";
        }
    }

    render() {
        const { click, employeeKey, employee, layoutData } = this.props;

        return (
            <div className={`employee-card-container`} style={StylesUtil.getEmployeeCardStyle(layoutData, false)} onClick={() => click(employeeKey, employee.firstName + ' ' + employee.secondName)} >

                <div className="employee-info-no-photo">
                    <div className="employee-flow-card-text" style={StylesUtil.getEmployeeNameStyle(layoutData)}>{employee.firstName + ' ' + employee.secondName}</div>
                    {ClientConfig.employeeDepartmentEnabled && <div style={StylesUtil.getEmployeeDepartmentTitleStyle(layoutData)}>{employee.department}</div>}
                    {ClientConfig.employeeJobTitlenabled && <div style={StylesUtil.getEmployeeJobTitleStyle(layoutData)}>{employee.jobTitle}</div>}
                    {ClientConfig.employeePhoneNumberEnabled && <div style={StylesUtil.getEmployeePhoneNumberStyle(layoutData)}>{employee.phoneNumber}</div>}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState): Partial<ILocalizationState & ILayoutDataState> => {
    const { language } = state.localization;
    const { layoutData } = state.layoutData

    return {
        language, layoutData
    };
};

export default connect(
    mapStateToProps
)(EmployeeCard);
