import UrlConfig from '../config/urlConfig';

export const getAllEmployeesRequest = async (companyId: number) => {
    const apiUrl = await UrlConfig.getApiUrl();

    const request: IRequest<any> = {
        url: apiUrl + `/clients/temporary/${companyId}`
    };

    return request;
}