export default class ClientConfig {
    static flow: FlowSettings = {
        carrierFlowEnabled: true,
        doesNotHaveAppointmentEnabled: true,
        informAllEnabled: false,
        checkoutEnabled: true,
    };
    static layout: Dictionary<PageType, PageLayoutSettings> = {
        'PausePage': { changeLanguageEnabled: true, clockEnabled: true },
        'CustomFieldsPage': { changeLanguageEnabled: true, clockEnabled: true },
        'FrontPage': { changeLanguageEnabled: true, clockEnabled: true },
        'MainFieldsPage': { changeLanguageEnabled: true, clockEnabled: true },
        'MessagePage': { changeLanguageEnabled: true, clockEnabled: true },
        'PhoneNumberPage': { changeLanguageEnabled: true, clockEnabled: true },
        'EmployeePage': { changeLanguageEnabled: true, clockEnabled: true },
        'CheckoutListPage': { changeLanguageEnabled: true, clockEnabled: true },
        'ConditionPage': { changeLanguageEnabled: true, clockEnabled: true },
        'ConditionVideoPage': { changeLanguageEnabled: true, clockEnabled: true },
        'DepartmentPage': { changeLanguageEnabled: true, clockEnabled: true },
        'ConditionPdfPage': { changeLanguageEnabled: true, clockEnabled: true },
        'LastInOfficePage': { changeLanguageEnabled: true, clockEnabled: true },
        'CustomFlowcardPage': { changeLanguageEnabled: true, clockEnabled: true },
        'VisitorInformationInputsPage': { changeLanguageEnabled: true, clockEnabled: true },
    };
    static mainColor: string = '#6495ED';
    static secondaryColor: string = '#D3D3D3';
    static tertiaryColor: string = '#000000';
    static language: LocalizationLanguage = 'EN';
    static layoutVersion: LayoutVersion = 'v1';
    static logoId: number = 0;
    static templateBackgroundId: number = 0;
    static secondaryTextColor: string = 'white';
    static mainTextColor: string = 'white';
    static tertiaryTextColor: string = 'black';
    static employeePhotoEnabled: boolean = false;
    static employeeDepartmentEnabled: boolean = false;
    static employeeJobTitlenabled: boolean = false;
    static employeePhoneNumberEnabled: boolean = false;
}
