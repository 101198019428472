export class MyClock {
    // Declare a class variable of type "Element" called el
    el: Element;
    monthStrings = ["Januar", "Februar", "Marts", "April", "Maj", "Juni", "Juli", "August", "September", "Oktober", "November", "December"];
    /**
     * Called when "new" is invoked, you'll pass your target element here.
     * @param element Target element to update
     */
    constructor(element: Element) {
        this.el = element;

        // Immediately kick off a setInterval to this objects "run" method,
        // every 1000ms like you had before.
        this.run()
        setInterval(() => this.run(), 5000)
    }


    /**
     * This *could* be in the constructor, but for seperation of duties we'll
     * make it a method.  This method is invoked every ~1000ms (JavaScript timers
     * aren't perfect).
     */
    run() {
        var time = new Date();
        // Don't need to call toString, but it's fine here. When you start
        // concatenating numbers onto strings they get converted anyway.
        var hours = time.getHours().toString();
        var minutes = time.getMinutes().toString();
        var seconds = time.getSeconds().toString();
        var date = time.getDate().toString();
        var month = time.getUTCMonth().toString();



        var monthstring = this.monthStrings[Number(month)];

        

        // Your previous logic.
        if (hours.length < 2) {
            hours = '0' + hours;
        }

        if (minutes.length < 2) {
            minutes = '0' + minutes;
        }

        if (seconds.length < 2) {
            seconds = '0' + seconds;
        }



        var clockStr = hours + ':' + minutes + " | " + date + '. ' + monthstring;

        // Update this class' "el" variable as before.
        this.el.textContent = clockStr;
    }
}