import { ActionCreator } from 'redux';

import {
    ConfigDataActionTypes as action,
    ISetConfigDataAction,
    ISetLanguageDataAction,
} from './types';

export const setConfigData: ActionCreator<ISetConfigDataAction> = (
    configData: MainConfigDTO,
) => ({
    type: action.SET_CONFIG_DATA,
    payload: {
        configData,
    },
});

export const setLanguageData: ActionCreator<ISetLanguageDataAction> = (
    languageData: LanguageOptionDTO[],
) => ({
    type: action.SET_LANGUAGE_DATA,
    payload: {
        languageData,
    },
});
