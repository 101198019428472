import { Action } from 'redux';

export interface IEmployeeState {
    employees: EmployeeDTO[];
}

export enum EmployeeActionTypes {
    SET_EMPLOYEES = 'SET_EMPLOYEES'
}

export interface ISetEmployeesAction extends Action {
    type: EmployeeActionTypes.SET_EMPLOYEES;
    payload: {
        employees: EmployeeDTO[];
    };
}

export type EmployeeActions =
    | ISetEmployeesAction