import { PrimaryButton } from '@fluentui/react';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { LocalizationDictionary } from '../../../localization/localizationDictionary';
import { IApplicationState } from '../../../store';
import { resetStoreState } from '../../../store/Flow/actions';
import { ILayoutDataState } from '../../../store/LayoutData/types';
import { ILocalizationState } from '../../../store/Localization/types';
import StylesUtil from '../../../utils/StylesUtil';
import './index.css';


interface IDispatchProps {
    resetStoreState: typeof resetStoreState;
}

type Props = IDispatchProps & ILocalizationState & ILayoutDataState;

class HomeButton extends React.Component<Props> {

    private readonly strings = LocalizationDictionary.getStrings;

    private buttonClick = () => {
        const { resetStoreState, layoutsRedirect, layoutData } = this.props;

        if (layoutsRedirect.config.has(layoutData.code)) {
            window.location.assign(layoutsRedirect.config.get(layoutData.code).url)
        } else {
            resetStoreState();
        }
    }

    getHomeString(): string {
        const { layoutData } = this.props;

        if (layoutData.code === "Kjaergaard") {
            return this.strings().home.toUpperCase()
        } else if (layoutData.code === "BirchEjendomme") {
            return ""
        }

        return this.strings().home
    }

    render() {
        const { layoutData } = this.props;

        return (
            <PrimaryButton styles={StylesUtil.getHomeButtonStyles(layoutData)} iconProps={{ iconName: 'Home' }} text={this.getHomeString()} onClick={this.buttonClick} />
        );
    }
}

const mapStateToProps = (state: IApplicationState): Partial<ILocalizationState & ILayoutDataState> => {
    const { language } = state.localization;
    const { layoutData, layoutsRedirect } = state.layoutData;

    return {
        language, layoutData, layoutsRedirect
    };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
    return bindActionCreators(
        { resetStoreState },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomeButton);

