import UrlConfig from '../config/urlConfig';


export const getAppointmentsByDate = async (companyId: number, startDate: string, endDate: string) => {

    const apiUrl = await UrlConfig.getApiUrl();

    const request: IRequest<any> = {
        url: apiUrl + `/api/CheckInAppointments?AccountId=${companyId}&StartDate=${startDate}&EndDate=${endDate}`
    };

    return request;
}