import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import ClientConfig from '../../../config/clientConfig';
import { PrimaryButton } from '@fluentui/react'
import { IApplicationState } from '../../../store';
import { resetStoreState, setCurrentPage } from '../../../store/Flow/actions';
import { IFlowState } from '../../../store/Flow/types';
import { LocalizationDictionary } from '../../../localization/localizationDictionary';

import './index.css';
import { ILayoutDataState } from '../../../store/LayoutData/types';
import ConditionalVideo from '../../LayoutElements/ConditionalVideo/ConditionalVideo';
import StylesUtil from '../../../utils/StylesUtil';
import { ILocalizationState } from '../../../store/Localization/types';

interface ILogo {
    logo: any;
}

interface IState {
    continueEnabled: boolean;
    checked: boolean;
    videoDone: boolean;
}

interface IDispatchProps {
    setCurrentPage: typeof setCurrentPage;
    resetStoreState: typeof resetStoreState;
}

type Props = IDispatchProps & IFlowState & ILogo & ILayoutDataState & ILocalizationState;

class ConditionVideoPage extends React.Component<Props, IState> {
    public readonly state: IState = {
        continueEnabled: false,
        checked: false,
        videoDone: false,
    }

    private readonly strings = LocalizationDictionary.getStrings;

    private nextButtonClicked = () => {
        const { setCurrentPage } = this.props;

        setCurrentPage("MessagePage" as PageType)
    }

    private addStyleButton(): any {
        const { layoutData } = this.props

        if (layoutData.code === "OfficePartner") {
            return ({ position: 'absolute', top: '31.5%', left: '82%' })
        }

    }

    // TODO: Special logo case
    private addMainFieldsPageTitle() {
        const { logo, layoutData } = this.props

        if (layoutData.code === "OfficePartner") {
            return (<h2 style={{ position: 'absolute', top: '25%' }}>{this.strings().mainfieldsPageTitle}</h2>)
        } else if (layoutData.code === "Reisswolf") {
            return (<div>
                <img className="logo" src={logo} onClick={this.iconClick} alt="Reisswolf logo" />
                <h2 className="Reisswolf-title" style={{ color: ClientConfig.mainColor }}>{this.strings().checkinreisswolf}</h2>
            </div>)
        }
    }

    private addCheckBox() {
        const { conditionData, layoutData, language } = this.props;
        var currentCondition = conditionData.find(({ localizationCode }) => localizationCode === language);

        if (layoutData.code === "Dragsbaek") {
            return (<div className="check-box-and-message">
                <label className="PhoneFriendly-condition-check-box">
                    <input style={StylesUtil.getCheckMarkStyle(layoutData)}
                        type="checkbox"
                        checked={this.state.checked}
                        onChange={() => {
                            this.setState({ continueEnabled: !this.state.checked && this.state.videoDone, checked: !this.state.checked })
                        }}
                    />
                </label>
                <p className="PhoneFriendly-condition-message2" style={({ color: ClientConfig.mainTextColor })}>{currentCondition.videoConfirmText}</p>
            </div>)
        } else {
            return (<div className="check-box-and-message">
                <label className="condition-check-box">
                    <input style={StylesUtil.getCheckMarkStyle(layoutData)}
                        type="checkbox"
                        checked={this.state.checked}
                        onChange={() => {
                            this.setState({ continueEnabled: !this.state.checked && this.state.videoDone, checked: !this.state.checked })
                        }}
                    />
                </label>
                <p className="condition-message2" style={({ color: ClientConfig.mainTextColor })}>{currentCondition.videoConfirmText}</p>
            </div>)
        }
    }

    private addNextButton(buttonDisabled: boolean) {
        const { layoutData } = this.props

        if (layoutData.code === "Reisswolf") {
            return (
                <button className="condition-continue2-button" style={buttonDisabled ? { fontFamily: 'Inter', backgroundColor: 'rgb(243, 242, 241)', color: 'rgb(210, 208, 206)' } : { fontFamily: 'Inter', backgroundColor: ClientConfig.mainColor, color: ClientConfig.mainTextColor }} onClick={this.nextButtonClicked} disabled={buttonDisabled}>{this.strings().continue}</button>
            )
        } else if (layoutData.code === "Dragsbaek") {
            return (<div style={{ marginTop: '1vh' }}>
                <PrimaryButton style={this.addStyleButton()} styles={StylesUtil.getButtonStyles(layoutData)} className="custom-fields-button" text={this.strings().next} onClick={this.nextButtonClicked} disabled={buttonDisabled} allowDisabledFocus />
            </div>);
        } else {
            return (<div style={{ marginTop: '10px' }}>
                <PrimaryButton style={this.addStyleButton()} styles={StylesUtil.getButtonStyles(layoutData)} className="custom-fields-button" text={this.strings().next} onClick={this.nextButtonClicked} disabled={buttonDisabled} allowDisabledFocus />
            </div>);
        }
    }

    private addVideo() {
        const { layoutData, conditionData, language } = this.props
        var currentCondition = conditionData.find(({ localizationCode }) => localizationCode === language);

        if (layoutData.code === "Reisswolf") {
            return (<div className="Reisswolf-video-container">
                <ConditionalVideo videoId="conditionalVideo" videoUrl={currentCondition.videoURL} onDone={this.videoFinished} />
            </div>)
        } else if (layoutData.code === "HIN") {
            return (<div className="Hin-video-container">
                <ConditionalVideo videoId="conditionalVideo" videoUrl={currentCondition.videoURL} onDone={this.videoFinished} />
            </div>);
        } else if (layoutData.code === "Kjaergaard") {
            return (<div className="Kjaergaard-video-container">
                <ConditionalVideo videoId="conditionalVideo" videoUrl={currentCondition.videoURL} onDone={this.videoFinished} />
            </div>);
        } else if (layoutData.code === "Dragsbaek") {
            return (<div className="PhoneFriendly-video-container">
                <ConditionalVideo videoId="conditionalVideo" videoUrl={currentCondition.videoURL} onDone={this.videoFinished} />
            </div>);
        } else {
            return (<div className="video-container">
                <ConditionalVideo videoId="conditionalVideo" videoUrl={currentCondition.videoURL} onDone={this.videoFinished} />
            </div>);
        }
    }

    private iconClick = () => {
        const { resetStoreState } = this.props;

        resetStoreState();
    }

    private videoFinished = () => {
        this.setState({ continueEnabled: this.state.checked, videoDone: true })
    }

    render() {
        const { currentPage } = this.props;
        const { continueEnabled } = this.state;

        if (currentPage === "ConditionVideoPage") {
            return (
                <div>
                    {this.addVideo()}
                    <div className="main-fields-container">
                        <div className="main-text-fields-container">
                            {this.addMainFieldsPageTitle()}
                        </div>
                        <div className="button-text-container">
                            {this.addCheckBox()}
                            {this.addNextButton(!continueEnabled)}
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return (<div>Error</div>);
        }

    }
}

const mapStateToProps = (state: IApplicationState): Partial<IFlowState & ILayoutDataState & ILocalizationState> => {
    const { currentPage, currentFlow } = state.flow;
    const { layoutData, conditionData } = state.layoutData;
    const { language } = state.localization;

    return {
        currentPage, currentFlow, layoutData, conditionData, language
    };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
    return bindActionCreators(
        { setCurrentPage, resetStoreState },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConditionVideoPage);
