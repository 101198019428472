import * as React from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { KeyboardLayoutDesign } from "../../../store/LayoutData/types";
import './index.css';

interface IState {
    layoutName: KeyboardLayoutType;
    input: string;
    multiFieldInputName: string;
}

interface IProps {
    onChange: (input: string) => void;
    keyboardRef: React.MutableRefObject<typeof Keyboard>;
    small?: boolean
    medium?: boolean
    layoutName?: KeyboardLayoutType
    layoutDesign?: KeyboardLayoutDesign
    input: string
    multiFieldInputName?: string
}

class KeyboardWrapper extends React.Component<IProps, IState> {

    public readonly state: IState = {
        layoutName: "upperCaseAlphabet",
        input: "",
        multiFieldInputName: ""
    }

    componentDidMount() {
        this.setState({ layoutName: this.props.layoutName ?? "upperCaseAlphabet" })
    }

    // Update internal state when props change
    componentDidUpdate(prevProps: IProps) {
        const { layoutName, input, multiFieldInputName } = this.props;
        //console.log("updated", this.props, this.state)

        // Update the field focus
        if (multiFieldInputName && prevProps.multiFieldInputName !== multiFieldInputName) {
            this.setState({ multiFieldInputName });
        }

        // Handle layout change
        if (prevProps.layoutName !== layoutName) {
            this.setState({ layoutName });
        }

        // Handle input change and adjust layout accordingly
        if (prevProps.input !== input) {
            let newLayoutName = this.state.layoutName;

            // Only adjust layout when switching to a text-based input
            if (layoutName !== "numpad") {
                if (input.length === 0 || input.endsWith(" ")) {
                    // If input is empty or ends with space, switch to uppercase layout
                    newLayoutName = 'upperCaseAlphabet';
                } else if (input.length > 0 && !input.endsWith(" ")) {
                    // Switch to default layout if input is not empty and doesn't end with a space
                    newLayoutName = 'default';
                }
            }

            // Only update state if the layout actually needs to change
            if (newLayoutName !== this.state.layoutName) {
                this.setState({ layoutName: newLayoutName });
            }

            // Update input state
            this.setState({ input });
        }
    }

    private onKeyPress = (button: string) => {
        const { layoutName } = this.state;

        if (button === "{shift}") {
            let newLayout: KeyboardLayoutType = layoutName === "default" ? "shift" : "default";
            this.setState({ layoutName: newLayout });
        }
    };

    render() {
        const { onChange, keyboardRef, small, medium } = this.props;
        let className = medium ? "medium" : (small ? "small" : "big"); // legacy, still used
        switch (this.props.layoutDesign) {
            case KeyboardLayoutDesign.SMALL:
                className = "small"
                break;
            case KeyboardLayoutDesign.MEDIUM:
                className = "medium"
                break;
            case KeyboardLayoutDesign.BIG:
                className = "big"
                break;
            case KeyboardLayoutDesign.NUMPAD:
                className = "numpad"
                break;

            default:
        }

        return (
            <div className={className}>
                <Keyboard
                    keyboardRef={(r: any) => (keyboardRef.current = r)}
                    layoutName={this.state.layoutName}
                    onChange={onChange}
                    onKeyPress={this.onKeyPress}
                    display={
                        { '{bksp}': '<' }
                    }
                    mergeDisplay={true}
                    inputName={this.props.multiFieldInputName}
                    layout={{
                        'default': [
                            '1 2 3 4 5 6 7 8 9 0 + ´ {bksp}',
                            'q w e r t y u i o p å ¨',
                            'a s d f g h j k l æ ø \'',
                            '{shift} z x c v b n m , . -',
                            '@ {space}'
                        ],
                        'shift': [
                            '! @ # ¤ % & / ( ) = ? ` {bksp}',
                            'Q W E R T Y U I O P Å ^',
                            'A S D F G H J K L Æ Ø *',
                            '{shift} Z X C V B N M ; : _',
                            '@ {space}'
                        ],
                        'upperCaseAlphabet': [
                            '1 2 3 4 5 6 7 8 9 0 + ´ {bksp}',
                            'Q W E R T Y U I O P Å ^',
                            'A S D F G H J K L Æ Ø *',
                            '{shift} Z X C V B N M ; : _',
                            '@ {space}'
                        ],
                        'numpad': [
                            '1 2 3',
                            '4 5 6',
                            '7 8 9',
                            '+ 0 {bksp}'
                        ]
                    }}
                />
            </div>
        );


    }

}

export default KeyboardWrapper;
