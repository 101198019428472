import * as React from 'react';
import { connect } from 'react-redux';
import { dispatchRequest } from '../../../api/apiUtils';
import { getCompanyImage } from '../../../api/fileServiceApi';
import ClientConfig from '../../../config/clientConfig';
import { IApplicationState } from '../../../store';
import { ILayoutDataState } from '../../../store/LayoutData/types';
import { ILocalizationState } from '../../../store/Localization/types';
import StylesUtil from '../../../utils/StylesUtil';
import './index.css';


type KeyType = number | string;

interface IProps {
    employeeKey: KeyType;
    employee: any;
    click: (key: KeyType, employeeName: string) => void;
    id: any;
    employeeImage: string;
}

interface IState {

}

type Props = IProps & ILocalizationState & ILayoutDataState;

class EmployeeCardWithPhoto extends React.Component<Props, IState> {

    componentDidMount = async () => {
        if(this.props.employee.imageId !== 0) {
            const employeeImage = await this.getCompanyImage(this.props.employee.imageId);
            this.setState({ employeeImage })    
        }    
    }

    private getCompanyImage = async (imageId: number) => {
        try {
            let image = (await dispatchRequest(await getCompanyImage(imageId))) as ImageDTO;
            return image.file;
        }
        catch {
            return ""
        }
    }

    private addImageWithStyle(employeeImage: string) {
        const { layoutData } = this.props

        if (ClientConfig.layoutVersion === 'v3' && layoutData.code === "SociatyOfLife") {
            return (<img className="employee-image" src={employeeImage} style={{ border: '1px solid ' + ClientConfig.mainColor, marginLeft: 'auto', marginRight: 'auto', marginTop: 15 }} alt="Employee" />)
        } else if (ClientConfig.layoutVersion === 'v3' && layoutData.code === "Retograad") {
            return (<img src={employeeImage} style={{ width: 180, height: 120, padding: 0, margin: 0, position: 'absolute', top: '130px' }} alt="Employee" />)

        }else {
            return (<img className="employee-image" src={employeeImage} alt="Employee" />)
        }
    }

    private addName(employee: any) {
        const { layoutData } = this.props

        if (ClientConfig.layoutVersion === 'v3' && layoutData.code === "SociatyOfLife") {
            return (<div className="employee-flow-card-text" style={StylesUtil.getEmployeeNameStyle(layoutData)}>{employee.firstName}<br /> {employee.secondName}</div>)
        } else if (ClientConfig.layoutVersion === 'v3' && layoutData.code === "Retograad") {
            return (<div className="employee-flow-card-text" style={StylesUtil.getEmployeeNameStyle(layoutData)}>{employee.firstName + ' ' + employee.secondName}</div>)

        } else {
            return (<div className="employee-flow-card-text" style={StylesUtil.getEmployeeNameStyle(layoutData)}>{employee.firstName + ' ' + employee.secondName}</div>)
        }
    }

    private addDepartment(employee: any) {
        const { layoutData } = this.props

        if (layoutData.code === "SociatyOfLife") {
            
        } else {
            return (ClientConfig.employeeDepartmentEnabled && <div style={StylesUtil.getEmployeeDepartmentTitleStyle(layoutData)}>{employee.department}</div>);
        }
    }

    private addJobTitle(employee: any) {
        const { layoutData } = this.props

        if (layoutData.code === "SociatyOfLife") {

        } else if (layoutData.code === "Retograad") {
            return (ClientConfig.employeeJobTitlenabled && <div style={StylesUtil.getEmployeeJobTitleStyle(layoutData)}>{employee.jobTitle}</div>);
        } else {
            return (ClientConfig.employeeJobTitlenabled && <div style={StylesUtil.getEmployeeJobTitleStyle(layoutData)}>{employee.jobTitle}</div>);
        }
    }

    private addPhoneNumber(employee: any) {
        const { layoutData } = this.props

        if (layoutData.code === "SociatyOfLife") {
            
        } else {
            return (ClientConfig.employeePhoneNumberEnabled && <div style={StylesUtil.getEmployeePhoneNumberStyle(layoutData)}>{employee.phoneNumber}</div>);
        }
    }

    render() {
        const { click, employeeKey, employee, employeeImage, layoutData } = this.props;

        return (
            <div className={`employee-card-container-with-photo employee-${ClientConfig.layoutVersion}`} style={StylesUtil.getEmployeeCardStyle(layoutData, true)} onClick={() => click(employeeKey, employee.firstName + ' ' + employee.secondName)} >

                {this.addImageWithStyle(employeeImage)}

                <div className="employee-info">
                    {this.addName(employee)}
                    {this.addDepartment(employee)}
                    {this.addJobTitle(employee)}
                    {this.addPhoneNumber(employee)}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState): Partial<ILocalizationState & ILayoutDataState> => {
    const { language } = state.localization;
    const { layoutData } = state.layoutData

    return {
        language, layoutData
    };
};

export default connect(
    mapStateToProps
)(EmployeeCardWithPhoto);
