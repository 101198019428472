import { Reducer } from 'redux';
import {
    FlowActions,
    FlowActionTypes as actions,
    IFlowState,
} from './types';


//ændre tilbage til front page og find en måde at sætte layout3 med pausepage som startskærm eller lav så timeout får den til at gå i pauseskærm
export const initialState: IFlowState = {
    currentPage: 'FrontPage',
    currentFlow: undefined,
    phoneNumberExists: false,
    responsibleEmployeeId: 0,
    responsibleEmployeeName: ''
};

const flowReducer: Reducer<IFlowState> = (
    state: IFlowState = initialState,
    action: FlowActions
) => {
    switch (action.type) {
        case actions.SET_CURRENT_PAGE:
            return { ...state, currentPage: action.payload.page };
        case actions.SET_CURRENT_FLOW:
            return { ...state, currentFlow: action.payload.flow };
        case actions.SET_PHONE_NUMBER_EXISTS:
            return { ...state, phoneNumberExists: action.payload.phoneNumberExists };
        case actions.SET_RESPONSIBLE_EMPLOYEE:
            return { ...state, responsibleEmployeeId: action.payload.id };
        case actions.SET_RESPONSIBLE_EMPLOYEE_NAME:
            return { ...state, responsibleEmployeeName: action.payload.name };
        default:
            return state;
    }
}

export default flowReducer;
