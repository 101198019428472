import { ActionCreator } from 'redux';

import {
    AccountValueActionTypes as action,
    ISetCSolutionInOfficeAction,
    ISetTMSASInOfficeAction
} from './types';

export const setCSolutionInOffice: ActionCreator<ISetCSolutionInOfficeAction> = (
    csolutionInOffice: AccountValueDTO,
) => ({
    type: action.SET_CSOLUTION_IN_OFFICE,
    payload: {
        csolutionInOffice,
    },
});

export const setTMSASInOffice: ActionCreator<ISetTMSASInOfficeAction> = (
    tmsasInOffice: AccountValueDTO,
) => ({
    type: action.SET_TMSAS_IN_OFFICE,
    payload: {
        tmsasInOffice,
    },
});
