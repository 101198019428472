import UrlConfig from '../config/urlConfig';

export const finilizeFlowRequest = async (companyId: number, client: CustomerDTO, employeeId: number, flow: FlowType, purpose: string) => {
    client.companyId = companyId;

    const apiUrl = await UrlConfig.getApiUrl();

    const request: IRequest<CustomerDTO> = {
        url: apiUrl + `/api/CheckInCustomers/finalizeFlow/${employeeId}/${companyId}?FlowType=${flow}&Purpose=${purpose}`,
        body: client
    };

    return request;
}

export const getCompanyNameRequest = async (companyId: number) => {
    const apiUrl = await UrlConfig.getApiUrl();

    const request: IRequest<CustomerDTO> = {
        url: apiUrl + `/Accounts/temporary/${companyId}`
    };

    return request;
}

export const getEmployeeRequest = async (employeeId: number) => {
    const apiUrl = await UrlConfig.getApiUrl();

    const request: IRequest<CustomerDTO> = {
        url: apiUrl + `/clients/anotherOne/${employeeId}`
    };

    return request;
}

export const sendEmail = async (emailInfo: SendEmailDTO) => {
    const apiUrl = await UrlConfig.getApiUrl();

    const request: IRequest<SendEmailDTO> = {
        url: apiUrl + `/api/CheckInMessage/sendemail`,
        body: emailInfo,
    };

    return request;
}

export const sendSMSRequest = async (smsInfo: SMSInfoDTO) => {
    const apiUrl = await UrlConfig.getApiUrl();

    const request: IRequest<SMSInfoDTO> = {
        url: apiUrl + `/api/CheckInMessage/sendSMS`,
        body: smsInfo,
    };

    return request;
}