import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { IApplicationState } from '../../../store';
import { resetStoreState, setCurrentPage } from '../../../store/Flow/actions';
import { setCSolutionInOffice, setTMSASInOffice } from '../../../store/AccountValues/actions';
import { IFlowState } from '../../../store/Flow/types';

import './index.css';
import { ILayoutDataState } from '../../../store/LayoutData/types';
import { IConfigDataState } from '../../../store/ConfigData/types';
import { IAccountValueState } from '../../../store/AccountValues/types';
import { setAccountValue } from '../../../api/companyApi';
import { dispatchRequest } from '../../../api/apiUtils';

interface ILogo {
    logo: any;
}

interface IState {
}

interface IDispatchProps {
    setCurrentPage: typeof setCurrentPage;
    resetStoreState: typeof resetStoreState;
    setCSolutionInOffice: typeof setCSolutionInOffice;
    setTMSASInOffice: typeof setTMSASInOffice;
}

type Props = IDispatchProps & IFlowState & ILogo & ILayoutDataState & IConfigDataState & IAccountValueState;

class LastInOfficePage extends React.Component<Props, IState> {
    private addButtons() {
        const { layoutData, csolutionInOffice, tmsasInOffice } = this.props
        const csolutionValue = csolutionInOffice.value !== "0";
        const tmsasValue = tmsasInOffice.value !== "0";

        if (layoutData.code === "CSolutionHouse") {
            return (<div style={this.styleArea()}>
                <div style={this.styleRow()}>
                    <div style={this.styleCard(600, 200)}>
                        <img style={this.styleImage()} src={window.location.origin + "/images/CSolution.png"} alt="Settings button" />
                    </div>
                    {this.addInOfficeButton(csolutionValue, this.setCSolution)}
                </div>
                <div style={this.styleRow()}>
                    <div style={this.styleCard(600, 200)}>
                        <img style={this.styleImage()} src={window.location.origin + "/images/TMSAS.png"} alt="Settings button" />
                    </div>
                    {this.addInOfficeButton(tmsasValue, this.setTMASSolution)}
                </div>
            </div>)
        } else {
            return (<div></div>)
        }
    }

    private addInOfficeButton(inOffice: boolean, clickEvent: any) {
        return (inOffice ?
            <div style={this.styleCard(200, 200)} onClick={clickEvent}>
                <img style={this.styleImage()} src={window.location.origin + "/images/InOffice.png"} alt="Settings button" />
            </div> :
            <div style={this.styleCard(200, 200)} onClick={clickEvent}>
                <img style={this.styleImage()} src={window.location.origin + "/images/OutOfOffice.png"} alt="Settings button" />
            </div>)
    }

    render() {
        const { currentPage } = this.props;

        if (currentPage === "LastInOfficePage") {
            return (
                <div>
                    {this.addButtons()}
                </div>
            );
        }
        else {
            return (<div>Error</div>);
        }
    }

    private styleArea(): React.CSSProperties {
        return ({
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'transparent',
            justifyContent: 'space-between',
            padding: '50px'
        })
    }

    private styleRow(): React.CSSProperties {
        return ({
            display: 'flex',
            flexDirection: 'row',
        })
    }

    private styleCard(width: number, height: number): React.CSSProperties {
        return ({
            display: 'flex',
            width: width,
            height: height,
            backgroundColor: 'transparent',
            justifyContent: 'center',
            marginLeft: '50px',
        })
    }

    private styleImage(): React.CSSProperties {
        return ({
            maxWidth: '100%',
            maxHeight: '100%',
            margin: 'auto',
        })
    }

    private setCSolution = async () => {
        const { setCSolutionInOffice, csolutionInOffice } = this.props
        const csolutionValue = csolutionInOffice.value !== "0";

        try {
            const valueToSend = {
                value: csolutionValue ? "0" : "1",
            }
            let response = await dispatchRequest(await setAccountValue(154, "OfficeEmpty", valueToSend)); // TODO: Remove awful hack
            setCSolutionInOffice(response);
        }
        catch {
            console.log("Set C-Solution account value failed.");
        }
    }

    private setTMASSolution = async () => {
        const { setTMSASInOffice, tmsasInOffice } = this.props
        const tmsassolutionValue = tmsasInOffice.value !== "0";

        try {
            const valueToSend = {
                value: tmsassolutionValue ? "0" : "1",
            }
            let response = await dispatchRequest(await setAccountValue(168, "OfficeEmpty", valueToSend)); // TODO: Remove awful hack
            setTMSASInOffice(response);
        }
        catch {
            console.log("Set TMS-AS account value failed.");
        }
    }
}

const mapStateToProps = (state: IApplicationState): Partial<IFlowState & ILayoutDataState & IConfigDataState & IAccountValueState> => {
    const { currentPage, currentFlow } = state.flow;
    const { layoutData, conditionData } = state.layoutData;
    const { configData } = state.configData;
    const { csolutionInOffice, tmsasInOffice } = state.accountValueData;

    return {
        currentPage, currentFlow, layoutData, conditionData, configData, csolutionInOffice, tmsasInOffice
    };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
    return bindActionCreators(
        { setCurrentPage, resetStoreState, setCSolutionInOffice, setTMSASInOffice },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LastInOfficePage);
