import { Action } from 'redux';

export interface IConfigDataState {
    configData: MainConfigDTO | undefined,
    languageData: LanguageOptionDTO[] | undefined,
}

export enum ConfigDataActionTypes {
    SET_CONFIG_DATA = 'SET_CONFIG_DATA',
    SET_LANGUAGE_DATA = 'SET_LANGUAGE_DATA',
}

export interface ISetConfigDataAction extends Action {
    type: ConfigDataActionTypes.SET_CONFIG_DATA;
    payload: {
        configData: MainConfigDTO;
    };
}

export interface ISetLanguageDataAction extends Action {
    type: ConfigDataActionTypes.SET_LANGUAGE_DATA;
    payload: {
        languageData: LanguageOptionDTO[];
    };
}

export type ConfigDataActions =
    | ISetConfigDataAction
    | ISetLanguageDataAction
