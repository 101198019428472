import { ActionCreator } from 'redux';

import {
    LocalizationActionTypes as action,
    ISetLocalizationLanguageAction
} from './types';

export const setLocalizationLanguage: ActionCreator<ISetLocalizationLanguageAction> = (
    language: LocalizationLanguage,
) => ({
    type: action.SET_LOCALIZATION_LANGUAGE,
    payload: {
        language,
    },
});
