import UrlConfig from '../config/urlConfig';
import { getCompanyGuid } from '../utils/idUtils';

export const getConfigRequest = async (companyId: number) => {
    const apiUrl = await UrlConfig.getApiUrl();

    const request: IRequest<any> = {
        url: apiUrl + `/api/CheckInConfig/config/${companyId}`
    }

    return request;
}

export const getCheckInLayoutRequest = async (companyId: number) => {
    const apiUrl = await UrlConfig.getApiUrl();

    const request: IRequest<CheckInLayoutDTO> = {
        url: apiUrl + `/api/CheckInConfig/layout/${companyId}`
    };

    return request;
}

export const getConditionRequest = async (companyId: number) => {
    const apiUrl = await UrlConfig.getApiUrl();

    const request: IRequest<ConditionDTO[]> = {
        url: apiUrl + `/CheckInCondition/api/accounts/${companyId}`
    };

    return request;
}

export const getLanguageOptionsRequest = async (companyId: number) => {
    const apiUrl = await UrlConfig.getApiUrl();

    const request: IRequest<LanguageOptionDTO[]> = {
        url: apiUrl + `/CheckInLanguageOptions/api/accounts/${companyId}`
    };

    return request;
}

export const getDepartmentsRequest = async (companyId: number) => {
    const apiUrl = await UrlConfig.getApiUrl();

    const request: IRequest<DepartmentDTO[]> = {
        url: apiUrl + `/api/CheckInClientDepartment/accounts/${companyId}`
    };

    return request;
}

export const getCompanyRequest = async (companyId: number) => {
    const apiUrl = await UrlConfig.getApiUrl();

    const request: IRequest<any> = {
        url: apiUrl + `/api/CheckInConfig/mainConfig/${companyId}`
    }

    return request;
}

export const getFullyDetailedConfigRequest = async (companyId: number) => {
    const apiUrl = await UrlConfig.getApiUrl();

    const request: IRequest<MainConfigDTO> = {
        url: apiUrl + `/api/CheckInConfig/checkin/${companyId}`
    }

    return request;
}

export const getCompanyGuidFromId = async () => {
    const companyGuid = getCompanyGuid();

    const apiUrl = await UrlConfig.getApiUrl();

    const request: IRequest<any> = {
        url: apiUrl + `/Accounts/getid/${companyGuid}`
    }

    return request;
}

export const getAccountValue = async (companyId: number, code: string) => {
    const apiUrl = await UrlConfig.getApiUrl();

    const request: IRequest<AccountValueDTO> = {
        url: apiUrl + `/accountValues/account/${companyId}/code/${code}`
    }

    return request;
}

export const setAccountValue = async (companyId: number, code: string, valueDTO: any) => {
    const apiUrl = await UrlConfig.getApiUrl();

    const request: IRequest<any> = {
        url: apiUrl + `/accountValues/account/${companyId}/code/${code}`,
        body: valueDTO
    }

    return request;
}
