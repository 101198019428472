import { Action } from 'redux';

export interface IAccountValueState {
    csolutionInOffice: AccountValueDTO;
    tmsasInOffice: AccountValueDTO;
}

export enum AccountValueActionTypes {
    SET_CSOLUTION_IN_OFFICE = 'SET_CSOLUTION_IN_OFFICE',
    SET_TMSAS_IN_OFFICE = 'SET_TMSAS_IN_OFFICE',
}

export interface ISetCSolutionInOfficeAction extends Action {
    type: AccountValueActionTypes.SET_CSOLUTION_IN_OFFICE;
    payload: {
        csolutionInOffice: AccountValueDTO;
    };
}

export interface ISetTMSASInOfficeAction extends Action {
    type: AccountValueActionTypes.SET_TMSAS_IN_OFFICE;
    payload: {
        tmsasInOffice: AccountValueDTO;
    };
}

export type AccountValueActions =
    | ISetCSolutionInOfficeAction
    | ISetTMSASInOfficeAction