import * as React from 'react';
import IdleTimer from 'react-idle-timer';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { dispatchRequest } from '../../api/apiUtils';
import { getCompanyImage } from '../../api/fileServiceApi'
import { LocalizationDictionary } from '../../localization/localizationDictionary'
import { IApplicationState } from '../../store';
import { setCustomerData } from '../../store/CustomerData/action';
import { ICustomerDataState } from '../../store/CustomerData/types';
import { resetStoreState, setCurrentFlow, setCurrentPage, setPhoneNumberExists } from '../../store/Flow/actions';
import { IFlowState } from '../../store/Flow/types';
import { ILocalizationState } from '../../store/Localization/types';
import CustomFieldsPage from '../FlowElements/CustomFieldsPage/CustomFieldsPage';
import EmployeePage from '../FlowElements/EmployeePage/EmployeePage';
import FrontPage from '../FlowElements/FrontPage/FrontPage';
import MainFieldsPage from '../FlowElements/MainFieldsPage/MainFieldsPage';
import MessagePage from '../FlowElements/MessagePage/MessagePage';
import NumberPage from '../FlowElements/NumberPage/NumberPage';
import './index.css'
import PausePage from '../FlowElements/PausePage/PausePage';
import ClientConfig from '../../config/clientConfig';
import CheckoutListPage from '../FlowElements/CheckoutListPage/CheckoutListPage';
import { ILayoutDataState } from '../../store/LayoutData/types';
import ConditionPage from '../FlowElements/ConditionPage/ConditionPage';
import ConditionVideoPage from '../FlowElements/ConditionVideoPage/ConditionVideoPage';
import { IConfigDataState } from '../../store/ConfigData/types';
import DepartmentPage from '../FlowElements/DepartmentPage/DepartmentPage';
import StylesUtil from '../../utils/StylesUtil';
import { getCompanyGuidFromId, getConfigRequest } from '../../api/companyApi';
import ConditionPdfPage from '../FlowElements/ConditionPdfPage/ConditionPdfPage';
import LastInOfficePage from '../FlowElements/LastInOfficePage/LastInOfficePage';
import { IEmployeeState } from '../../store/Employees/types';
import CustomFlowcardPage from '../FlowElements/CustomFlowcardPage/CustomFlowcardPage';
import VisitorInformationInputsPage from '../FlowElements/VisitorInformationInputsPage/VisitorInformationInputsPage';


interface IState {
    companyAttributes: CompanyAttributeDTO[];
    companyId: number;
    logo: any;
}

interface IDispatchProps {
    setCurrentPage: typeof setCurrentPage;
    setCurrentFlow: typeof setCurrentFlow;
    setPhoneNumberExists: typeof setPhoneNumberExists;
    setCustomerData: typeof setCustomerData;
    resetStoreState: typeof resetStoreState;
}

type Props = IDispatchProps & IFlowState & ICustomerDataState & ILocalizationState & ILayoutDataState & IConfigDataState & IEmployeeState;

class ContentFlowPage extends React.Component<Props, IState> {
    public readonly state: IState = {
        companyAttributes: [
        ],
        companyId: 0,
        logo: ''
    }

    public lVersion = '';

    private readonly strings = LocalizationDictionary.getStrings;
    private readonly idleTimeout = 1000 * 15;

    private getCompanyImage = async (imageId: number) => {
        //console.log("1");
        try {
            let image = (await dispatchRequest(await getCompanyImage(imageId))) as ImageDTO;
            return image.file;
        }
        catch {
            return ""
        }
    }

    public setLayoutVersion(lVersion: string) {
        //console.log("2");
        this.lVersion = lVersion;
        //console.log("Layout version set in ContentFlowPage: " + this.lVersion)
    }


    private getFlowPage = () => {
        //console.log("3");
        const { currentPage } = this.props;
        const { logo } = this.state;
        const { companyId } = this.state;
        

        //render pages passing required props
        switch (currentPage) {
            case "PausePage":
                return (<PausePage companyId={companyId} />)
            case "FrontPage":
                return (<FrontPage logo={logo}/>);
            case "PhoneNumberPage":
                return (<NumberPage />)
            case "MainFieldsPage":
                return (<MainFieldsPage logo={logo} />);
            case "CustomFieldsPage":
                return (<CustomFieldsPage logo={logo} />);
            case "EmployeePage":
                return (<EmployeePage logo={logo} />); 
            case "MessagePage":
                return (<MessagePage logo={logo}/>);
            case "CheckoutListPage":
                return (<CheckoutListPage logo={logo} />)
            case "ConditionPage":
                return (<ConditionPage logo={logo} />)
            case "ConditionVideoPage":
                return (<ConditionVideoPage logo={logo} />)
            case "DepartmentPage":
                return (<DepartmentPage logo={logo} />)
            case "ConditionPdfPage":
                return (<ConditionPdfPage logo={logo} />)
            case "LastInOfficePage":
                return (<LastInOfficePage logo={logo} />)
            case "CustomFlowcardPage":
                return (<CustomFlowcardPage logo={logo} />);
            case "VisitorInformationInputsPage":
                return (<VisitorInformationInputsPage />)

        }
    }

    private getPageTitle = (): string => {
        //console.log("4");
        const { currentPage, layoutData, conditionData, configData, language } = this.props;
        var currentCondition = conditionData.find(({ localizationCode }) => localizationCode === language);

        //return title for current page
        switch (currentPage) {
            case "FrontPage":
                if (layoutData.code === "Visionhouse" || layoutData.code === "VismaHouse" || layoutData.code === "SSIDiagnosticaHouse" || layoutData.code === "CSolutionHouse" || layoutData.code === "FibiaHaslevHouse" || layoutData.code === "LauritzenHouse") {
                    return this.strings().welcome;
                } else if (layoutData.code === "DinexTMLHouse") {
                    //console.log(this.strings().WelcomeDinexTMLHouse)
                    return this.strings().WelcomeDinexTMLHouse;
                } else if (layoutData.code === "TotalEnergies") {
                    //console.log(this.strings().WelcomeDinexTMLHouse)
                    return this.strings().GuestRegistration;
                } else {
                    return this.strings().frontPageTitle + configData.name;
                }                
            case "PhoneNumberPage":
                if (configData.useKeyCard) {
                    return this.strings().keyCardPageTitle;
                } else {
                    return this.strings().numberPageTitle;
                }
            case "MainFieldsPage":
                if (layoutData.code === "EDForhandler") {
                    return this.strings().frontPageTitle + configData.name;
                } else if (layoutData.code === "Fundamentet") {
                    return "Velkommen til Fundamentet";
                } else {
                    return this.strings().mainfieldsPageTitle;
                }
            case "CustomFieldsPage":
                if (layoutData.code === "EDForhandler") {
                    return this.strings().frontPageTitle + configData.name;
                } else if (layoutData.code === "Sef" || layoutData.code === "Seftest") {
                    return this.strings().mainfieldsPageTitle;
                } else if (layoutData.code === "Kalundborg" || layoutData.code === "Comm2ig" || layoutData.code === "Kjaergaard") {
                    return this.strings().mainfieldsPageTitle;
                } else if (layoutData.code === "Dragsbaek") {
                    return this.strings().mainfieldsPageTitle;
                } else if (layoutData.code === "Flensted" || layoutData.code === "FlenstedWorkshop") {
                    return this.strings().customFieldsPageTitle;
                } else if (layoutData.code === "Meneta") {
                    return this.strings().customOfficeFieldsPageTitle;
                } else {
                    return '';
                }
            case "EmployeePage":
                if (layoutData.code === "EDForhandler") {
                    return this.strings().frontPageTitle + configData.name;
                } else if (layoutData.code === "Fundamentet") {
                    return "";
                } else if (layoutData.code === "TotalEnergies") {
                    return this.strings().employeePageTitleHost;
                } else {
                    return this.strings().employeePageTitle;
                }
            case "MessagePage":
                if (layoutData.code === "EDForhandler") {
                    return this.strings().frontPageTitle + configData.name;
                } else {
                    return "";
                }
            case "ConditionPage":
                return currentCondition.conditionHeader;
            case "ConditionVideoPage":
                return currentCondition.videoHeader;
            case "DepartmentPage":
                return this.strings().departmentsTitle;
            case "ConditionPdfPage":
                return currentCondition.pdfHeader;
            case "CustomFlowcardPage":
                return this.strings().CustomFlowcardPageTitle;

        }
    }

    private addTitelToPage() {
        //console.log("5");
        const { currentPage, layoutData } = this.props;
        var title;

        if (layoutData.code === "Retograad" || layoutData.code === "OfficePartner" || layoutData.code === "Reisswolf" || ((layoutData.code === "Flensted" || layoutData.code === "FlenstedWorkshop" || layoutData.code === "Lauritzen" || layoutData.code === "Bulkers") && currentPage === 'FrontPage')) { //Flot, rigtig flot..

        } else if (currentPage === 'LastInOfficePage') {
            title = <div style={StylesUtil.getHeaderContainerStyle(layoutData, currentPage)}>
                <div>
                    <img style={StylesUtil.getLanguageButtonStyle(layoutData)} src={window.location.origin + "/images/Cockwheel.png"} alt="Settings button" />
                </div>
                <h1 style={StylesUtil.getSubHeaderStyle(layoutData)}>{this.strings().lastInOfficeTitle}</h1>
            </div>
        } else if (currentPage === 'ConditionVideoPage' || currentPage === 'ConditionPdfPage') {
            if (layoutData.code === "Dragsbaek") {
                title = <div className="PhoneFriendly-header-container" style={{ marginTop: '-20vh' }}>
                    {this.getPageTitle()}
                </div>
            } else if (layoutData.code === "Meneta") {
                title = <div className="header-container">
                    {this.getPageTitle()}
                </div>
            } else {
                title = <div className="header-container" style={{ marginTop: '-320px' }}>
                    {this.getPageTitle()}
                </div>
            }
        } else if (layoutData.code === "SociatyOfLife") {
            title = <div className="header-container" style={{ marginTop: (currentPage === 'FrontPage' || currentPage === 'MessagePage') && '0px' }}>

                {currentPage === 'FrontPage' &&
                    <h1 style={{ color: '#707070' }}>What can we help you with?</h1>
                }
                {currentPage === 'PhoneNumberPage' &&
                    <h1 style={{ color: '#707070', height: 150 }}>Enter your information <div style={{ fontSize: 20, position: 'absolute', left: 350, top: 200 }}>We will store your information for 30 days and only use it to contact you if there is a case of Covid-19 at Society of Lifestyle</div></h1>
                }
                {currentPage === 'CustomFieldsPage' &&
                    <h1 style={{ color: '#707070', height: 150 }}>Enter your information <div style={{ fontSize: 20, position: 'absolute', left: 350, top: 200 }}>We will store your information for 30 days and only use it to contact you if there is a case of Covid-19 at Society of Lifestyle</div></h1>
                }
                {currentPage === 'MainFieldsPage' &&
                    <h1 style={{ color: '#707070', height: 150 }}>Enter your information <div style={{ fontSize: 20, position: 'absolute', left: 350, top: 200 }}>We will store your information for 30 days and only use it to contact you if there is a case of Covid-19 at Society of Lifestyle</div></h1>
                }
                {currentPage === 'EmployeePage' &&
                    <h1 style={{ color: '#707070', height: 150 }}>Find your contact person<div style={{ fontSize: 20, position: 'absolute', left: 670, top: 200 }}>Write the name of your contact and tap on their profile</div></h1>
                }

            </div>

        } else if (layoutData.code === "EDForhandler") {

            title = <div className="header-container" style={{ marginTop: (currentPage === 'FrontPage') && '0px' }}>
                <div style={{ color: ClientConfig.mainColor }}>{this.getPageTitle()}</div>
                {currentPage === 'FrontPage' &&
                    <h2 style={{ color: ClientConfig.mainColor, fontWeight: 'bolder', position: 'absolute', top: '70%' }}>{this.strings().edFrontPageTitle}</h2>
                }
            </div>

        } else if (layoutData.code === "Visionhouse" || layoutData.code === "VismaHouse" || layoutData.code === "SSIDiagnosticaHouse" || layoutData.code === "CSolutionHouse" || layoutData.code === "FibiaHaslevHouse" || layoutData.code === "LauritzenHouse") {
            title = <div style={StylesUtil.getHeaderContainerStyle(layoutData, currentPage)}>
                {this.getPageTitle()}
                {currentPage === 'FrontPage' &&
                    <h1 style={StylesUtil.getSubHeaderStyle(layoutData)}>{this.strings().visionSubfirma}</h1>
                }
            </div>

        } else if (layoutData.code === "DinexTMLHouse") {
            console.log(this.getPageTitle())
            title = <div style={StylesUtil.getHeaderContainerStyle(layoutData, currentPage)}>
                {this.getPageTitle()}
                {currentPage === 'FrontPage' &&
                    <h1 style={StylesUtil.getSubHeaderStyle(layoutData)}>{this.strings().visionSubfirma}</h1>
                }
            </div>
            console.log(title)
        } else if (layoutData.code === "Fundamentet") {
            title = <div className="header-container" style={{ marginTop: (currentPage === 'FrontPage' || currentPage === 'MessagePage') && '0px', fontSize: 40, margin: -50, marginLeft: 0, minWidth: 1500 }}>
                {this.getPageTitle()}
            </div>

        } else if (layoutData.code === "ErhvershusMidt") {
            title = <div style={StylesUtil.getHeaderContainerStyle(layoutData, currentPage)}>
                {this.getPageTitle()}
                {currentPage === 'FrontPage' &&
                    <h1 style={StylesUtil.getSubHeaderStyle(layoutData)}>{this.strings().Erhvervmidtjylland}</h1>
                }
            </div>
        } else if (layoutData.code === "Flensted" || layoutData.code === "FlenstedWorkshop") {
            title = <div style={StylesUtil.getHeaderContainerStyle(layoutData, currentPage)}>
                {this.getPageTitle()}
                {currentPage === 'DepartmentPage' &&
                    <h2 style={StylesUtil.getSubHeaderStyle(layoutData)}>{this.strings().flenstedDepartmentsSubTitle}</h2>
                }
            </div>
        } else if (layoutData.code === "LouisPoulsen") {
            title = <div style={StylesUtil.getHeaderContainerStyle(layoutData, currentPage)}>
                {this.getPageTitle()}
                {currentPage === 'FrontPage' &&
                    <h1 style={StylesUtil.getSubHeaderStyle(layoutData)}>{this.strings().frontPageTitleLouisP}</h1>
                }
            </div>
        } else if (layoutData.code === "TotalEnergies") {
            title = <div style={StylesUtil.getHeaderContainerStyle(layoutData, currentPage)}>
                {this.getPageTitle()}
            </div>
        } else {
            title = <div style={StylesUtil.getHeaderContainerStyle(layoutData, currentPage)}>
                {this.getPageTitle()}
                {currentPage === 'FrontPage' &&
                    <h1 style={StylesUtil.getSubHeaderStyle(layoutData)}>{this.strings().frontPageSubtext}</h1>
                }
            </div>
        }

        return title;
    }

    // add så den kan se version, så timeout kan sættes til pausePage for layout 3
    private getIdleTimeout = () => {
       //console.log("6");
        const { currentPage, layoutsRedirect, layoutData } = this.props;

        //console.log("sker timeout her ?")
        //timeout sker ikke her
        const second = 1000;
        let timeout = 0;

        switch (currentPage) {
            case "FrontPage":
                if (ClientConfig.layoutVersion === 'v3' || layoutsRedirect.config.has(layoutData.code)) {
                    timeout = 60 * second;
                } else {
                    timeout = 60000 * second;
                }
                
                break
            case "PhoneNumberPage":
                timeout = 60 * second;
                break;
            case "MainFieldsPage":
                if (layoutData.code === "Fundamentet") {
                    timeout = 60000 * second;
                    break;
                } else {
                    timeout = 60 * second;
                    break;
                }
            case "CustomFieldsPage":
                timeout = 60 * second;
                break;
            case "EmployeePage":
                //timeout = 60 * second;
                timeout = 6000 * second; // *NOTE Only for teste purposes
                break; 
            case "MessagePage":
                if (layoutData.code === "FibiaHaslev" || layoutData.code === "FibiaAndel" || layoutData.code === "FibiaMuseum") {
                    timeout = 30000 * second;
                    break;
                } else {
                    timeout = 600 * second;
                    break;
                }
            case "ConditionPage":
                timeout = 600 * second;
                break;
            case "ConditionVideoPage":
                timeout = 600 * second;
                break;
            case "DepartmentPage":
                timeout = 60 * second;
                break;
            case "ConditionPdfPage":
                timeout = 120 * second;
                break;
            case "LastInOfficePage":
                timeout = 20 * second;
                break;
            default:
                timeout = 60 * second;
                break;
        }

        //console.log("THIS IS THE PAGE" + currentPage)

        return timeout;
    }

    //add value version
    private handleOnIdle = () => {
       //console.log("7");
        const { resetStoreState, setCurrentPage, layoutData, layoutsRedirect } = this.props;

        if (this.lVersion === 'v3') {

            //console.log("v3 is in use"
            setCurrentPage('PausePage' as PageType);
        } else if (layoutsRedirect.config.has(layoutData.code)) {
            window.location.assign(layoutsRedirect.config.get(layoutData.code).url)
        } else {
            //console.log("no v3 here")
            resetStoreState();
        }
        
        //here is where it resets
    }

    public shouldComponentUpdate = (nextProps: Props, nextState: IState): boolean => {
       //console.log("8");
        const { companyAttributes } = this.state;
        const { language, currentPage } = this.props;

        if (companyAttributes.length !== nextState.companyAttributes.length) return true;
        if (language !== nextProps.language || currentPage !== nextProps.currentPage) return true;

        return false;
    }

    //added comapny id to check østjyllands brændvæsen
    public componentDidMount = async () => { // TODO: Why do we get image here?
       //console.log("9");
        const { language, languageData } = this.props;
        var currentLanguageOptions = languageData.find(({ localizationCode }) => localizationCode === language);
        let config: ClientConfigDTO;

        try {
            let companyId = await dispatchRequest(await getCompanyGuidFromId()); // TODO: Temporary untill we get validation

            config = await dispatchRequest(await getConfigRequest(companyId));
            const attributes = currentLanguageOptions && currentLanguageOptions.checkInCustomAttributes ? currentLanguageOptions.checkInCustomAttributes : [];
            const image = await this.getCompanyImage(config.logoId);

            this.setLayoutVersion(config.layoutVersion);

            this.setState({ companyAttributes: attributes, companyId: companyId, logo: image });
        }
        catch (e) {
           //console.log('Something wrong with api');
        }
    }

    render() {
       //console.log("10");
        const { layoutData, currentPage } = this.props

        var link = document.createElement('link');
        link.setAttribute('rel', 'stylesheet');
        link.setAttribute('type', 'text/css');
        link.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Inter:wght@200&display=swap');
        document.head.appendChild(link);


        //if (layoutData.code === "FibiaMuseum") {
        //    setCurrentPage('PhoneNumberPage' as PageType)
        //    setCurrentFlow('CheckinNoAppointment' as FlowType)
        //   console.log("her")
        //    console.log(currentFlow)
        //}

        return (
            <div style={StylesUtil.getContentPageContainerStyle(layoutData, currentPage)}>
                <IdleTimer
                    timeout={this.getIdleTimeout()}
                    onIdle={this.handleOnIdle}
                />
                {this.addTitelToPage()}

                <div style={StylesUtil.getContentContainerStyle(layoutData, currentPage)}>
                    {this.getFlowPage()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState): Partial<IFlowState & ICustomerDataState & ILocalizationState & ILayoutDataState & IConfigDataState & IEmployeeState> => {
    //console.log("11", state);

    const { layoutData, layoutsRedirect, conditionData } = state.layoutData;
    const { currentPage, currentFlow, phoneNumberExists } = state.flow;
    const { data } = state.customerData;
    const { language } = state.localization;
    const { configData, languageData } = state.configData;
    const { employees } = state.employeeData;

    return {
        currentPage, currentFlow, phoneNumberExists, data, language, layoutData, layoutsRedirect, conditionData, configData, languageData, employees
    };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
    //console.log("12");
    return bindActionCreators(
        {setCurrentPage, setCurrentFlow, setPhoneNumberExists, setCustomerData, resetStoreState},
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContentFlowPage);
