import { Reducer } from 'redux';
import {
    LocalizationActions,
    LocalizationActionTypes as actions,
    ILocalizationState,
} from './types';

export const initialState: ILocalizationState = {
    language: 'EN'
};

const localizationReducer: Reducer<ILocalizationState> = (
    state: ILocalizationState = initialState,
    action: LocalizationActions
) => {
    switch (action.type) {
        case actions.SET_LOCALIZATION_LANGUAGE:
            return { ...state, language: action.payload.language };
        default:
            return state;
    }
}

export default localizationReducer;