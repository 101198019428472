import { Action } from 'redux';

export interface ILocalizationState {
    language: LocalizationLanguage;
}

export enum LocalizationActionTypes {
    SET_LOCALIZATION_LANGUAGE = 'SET_LOCALIZATION_LANGUAGE'
}

export interface ISetLocalizationLanguageAction extends Action {
    type: LocalizationActionTypes.SET_LOCALIZATION_LANGUAGE;
    payload: {
        language: LocalizationLanguage;
    };
}

export type LocalizationActions =
    | ISetLocalizationLanguageAction