import { ActionCreator } from 'redux';

import {
    FlowActionTypes as action,
    ISetCurrentPageAction,
    ISetCurrentFlowAction,
    ISetPhoneNumberExistsAction,
    ISetResponsibleEmployeeAction,
    ISetResponsibleEmployeeNameAction,
    IResetStoreStateAction
} from './types';

export const setCurrentPage: ActionCreator<ISetCurrentPageAction> = (
    page: PageType,
) => ({
    type: action.SET_CURRENT_PAGE,
    payload: {
        page,
    },
});

export const setCurrentFlow: ActionCreator<ISetCurrentFlowAction> = (
    flow: FlowType,
) => ({
    type: action.SET_CURRENT_FLOW,
    payload: {
        flow,
    },
});

export const setPhoneNumberExists: ActionCreator<ISetPhoneNumberExistsAction> = (
    phoneNumberExists: boolean,
) => ({
    type: action.SET_PHONE_NUMBER_EXISTS,
    payload: {
        phoneNumberExists,
    },
});

export const setResponsibleEmployee: ActionCreator<ISetResponsibleEmployeeAction> = (
    id: number,
) => ({
    type: action.SET_RESPONSIBLE_EMPLOYEE,
    payload: {
        id,
    },
});

export const setResponsibleEmployeeName: ActionCreator<ISetResponsibleEmployeeNameAction> = (
    name: string,
) => ({
    type: action.SET_RESPONSIBLE_EMPLOYEE_NAME,
    payload: {
        name,
    },
});

export const resetStoreState: ActionCreator<IResetStoreStateAction> = () => ({
    type: action.RESET_STORE_STATE,
});
