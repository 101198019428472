import UrlConfig from '../config/urlConfig';

export const getCompanyImage = async (id: number) => {
    const apiUrl = await UrlConfig.getApiUrl();

    const request: IRequest<any> = {
        url: apiUrl + `/files/temporary/${id}`
    };

    return request;
}
