import * as React from 'react';
import { setCurrentFlow, setCurrentPage } from '../../../store/Flow/actions';
import { IApplicationState } from '../../../store';
import { IFlowState } from '../../../store/Flow/types';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import ClientConfig from '../../../config/clientConfig';
import { LocalizationDictionary } from '../../../localization/localizationDictionary';
import { dispatchRequest } from '../../../api/apiUtils';
import { getCompanyGuidFromId, getConfigRequest } from '../../../api/companyApi';
import { getCompanyImage } from '../../../api/fileServiceApi';


type CompID = number;


interface IDispatchProps {
    setCurrentPage: typeof setCurrentPage;
    setCurrentFlow: typeof setCurrentFlow;
}

interface IState {
    companyId: CompID;
    logo?: "";
}

type Props = IDispatchProps & IFlowState & IState;

class PausePage extends React.Component<Props> {
    private readonly strings = LocalizationDictionary.getStrings;

    public readonly state: IState = {
        logo: "",
        //id here is only for the state to work so we can get logo
        companyId: 0,
    }


    loadlogo = async (config: ClientConfigDTO) => {
        //console.log("config has this logo id: " + config.logoId);

        if (config?.logoId !== 0) {
            const logo = await this.getCompanyImage(config.logoId);
            this.setState({ logo })
        }
    }

    private getCompanyImage = async (imageId: number) => {
        try {
            let image = (await dispatchRequest(await getCompanyImage(imageId))) as ImageDTO;
            return image.file;
        }
        catch {
            return ""
        }
    }

    private loadConfig = async (companyId: number) => {
        let config: ClientConfigDTO;

        try {
            config = await dispatchRequest(await getConfigRequest(companyId));
        }
        catch (e) {

        }
        //we can get colour too
        if (config) {
            ClientConfig.logoId = config.logoId;
        }

        await this.loadlogo(config);
    }

    public componentDidMount = async () => {
        try {
            let companyId = await dispatchRequest(await getCompanyGuidFromId()); // TODO: Temporary untill we get validation
            await this.loadConfig(companyId);
        }
        catch {
            console.log("Failed to setup PausePage.")
        }
    }


    render() {
        const { currentPage } = this.props

        if (currentPage === "PausePage") {
            return (<div></div>);
        }
        else {
            return (<div>Error</div>);
        }

        
    }
}

const mapStateToProps = (state: IApplicationState): Partial<IFlowState> => {
    const { currentPage, currentFlow } = state.flow;

    return {
        currentPage, currentFlow
    };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
    return bindActionCreators(
        { setCurrentPage, setCurrentFlow },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PausePage);