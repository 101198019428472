import { ActionCreator } from 'redux';

import {
    LayoutDataActionTypes as action,
    ISetLayoutDataAction,
    ISetLayoutsSharedHomeAction,
    ISetLayoutsRedirectAction,
    ISetConditionDataAction,
    ISetDepartmentDataAction,
    ISetCurrentDepartmentAction,
} from './types';

export const setLayoutData: ActionCreator<ISetLayoutDataAction> = (
    layoutData: CheckInLayoutDTO,
) => ({
    type: action.SET_LAYOUT_DATA,
    payload: {
        layoutData,
    },
});

export const setLayoutsSharedHome: ActionCreator<ISetLayoutsSharedHomeAction> = (
    layoutsSharedHome: CheckInSharedHomeDTO,
) => ({
    type: action.SET_LAYOUTS_SHARED_HOME,
    payload: {
        layoutsSharedHome,
    },
});

export const setLayoutsRedirect: ActionCreator<ISetLayoutsRedirectAction> = (
    layoutsRedirect: CheckInRedirectHomeDTO,
) => ({
    type: action.SET_LAYOUTS_REDIRECT,
    payload: {
        layoutsRedirect,
    },
});

export const setConditionData: ActionCreator<ISetConditionDataAction> = (
    conditionData: ConditionDTO[],
) => ({
    type: action.SET_CONDITION_DATA,
    payload: {
        conditionData,
    },
});

export const setDepartmentData: ActionCreator<ISetDepartmentDataAction> = (
    departmentsData: DepartmentDTO[],
) => ({
    type: action.SET_DEPARTMENT_DATA,
    payload: {
        departmentsData,
    },
});

export const setCurrentDepartment: ActionCreator<ISetCurrentDepartmentAction> = (
    currentDepartment: number,
) => ({
    type: action.SET_CURRENT_DEPARTMENT,
    payload: {
        currentDepartment,
    },
});
