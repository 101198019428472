import { Reducer } from 'redux';
import {
    AccountValueActions,
    AccountValueActionTypes as actions,
    IAccountValueState,
} from './types';

export const initialState: IAccountValueState = {
    csolutionInOffice: { value: "", code: "", type: "" },
    tmsasInOffice: { value: "", code: "", type: "" },
};

const accountValueReducer: Reducer<IAccountValueState> = (
    state: IAccountValueState = initialState,
    action: AccountValueActions
) => {
    switch (action.type) {
        case actions.SET_CSOLUTION_IN_OFFICE:
            return { ...state, csolutionInOffice: action.payload.csolutionInOffice };
        case actions.SET_TMSAS_IN_OFFICE:
            return { ...state, tmsasInOffice: action.payload.tmsasInOffice };
        default:
            return state;
    }
}

export default accountValueReducer;