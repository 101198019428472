import axios from "axios";

export default class UrlConfig {
    private static apiUrl: string;
    private static fileServiceUrl: string;
    private static redirectUrl: string;

    public static async getApiUrl() {
        if (!UrlConfig.apiUrl) {
            UrlConfig.apiUrl = (await axios.get('Url/webApi')).data;
        }

        return UrlConfig.apiUrl;
    }

    public static async getFileServiceUrl() {
        if (!UrlConfig.fileServiceUrl) {
            UrlConfig.fileServiceUrl = (await axios.get('Url/fileServiceUrl')).data;
        }

        return UrlConfig.fileServiceUrl;
    }

    public static async getRedirectUrl() {
        if (!UrlConfig.redirectUrl) {
            UrlConfig.redirectUrl = (await axios.get('Url/redirectUrl')).data;
        }

        return UrlConfig.redirectUrl;
    }
}
