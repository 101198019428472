import { Reducer } from 'redux';
import {
    CustomerDataActions,
    CustomerDataActionTypes as actions,
    ICustomerDataState,
} from './types';

export const initialState: ICustomerDataState = {
    data: {
        customerInfos: [],
        firstName: "",
        secondName: "",
        phoneNumber: "",
        companyId: 0
    },
    customerCount: 0,
    customerPurpose: "",
};

const customerDataReducer: Reducer<ICustomerDataState> = (
    state: ICustomerDataState = initialState,
    action: CustomerDataActions
) => {
    switch (action.type) {
        case actions.SET_CUSTOMER_DATA:
            return { ...state, data: action.payload.data };
        case actions.SET_CUSTOMER_COUNT:
            return { ...state, customerCount: action.payload.customerCount };
        case actions.SET_CUSTOMER_PURPOSE:
            return { ...state, customerPurpose: action.payload.customerPurpose };
        default:
            return state;
    }
}

export default customerDataReducer;
