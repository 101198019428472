import { combineReducers, Reducer } from 'redux';

import localizationReducer from './Localization/reducer';
import { ILocalizationState } from './Localization/types';

import flowReducer from './Flow/reducer';
import { IFlowState } from './Flow/types';

import customerDataReducer from './CustomerData/reducer';
import { ICustomerDataState } from './CustomerData/types';

import layoutDataReducer from './LayoutData/reducer';
import { ILayoutDataState } from './LayoutData/types';

import configDataReducer from './ConfigData/reducer';
import { IConfigDataState } from './ConfigData/types';

import accountValueReducer from './AccountValues/reducer';
import { IAccountValueState } from './AccountValues/types';

import employeeReducer from './Employees/reducer';
import { IEmployeeState } from './Employees/types';


export interface IApplicationState {
    localization: ILocalizationState;
    flow: IFlowState;
    customerData: ICustomerDataState;
    layoutData: ILayoutDataState;
    configData: IConfigDataState;
    accountValueData: IAccountValueState;
    employeeData: IEmployeeState;
}

const appReducer: Reducer<IApplicationState> = combineReducers<
  IApplicationState
>({
    localization: localizationReducer,
    flow: flowReducer,
    customerData: customerDataReducer,
    layoutData: layoutDataReducer,
    configData: configDataReducer,
    accountValueData: accountValueReducer,
    employeeData: employeeReducer,
});

export const rootReducer: Reducer<IApplicationState> = (
    state: IApplicationState,
    action
) => {
    if (action.type === 'RESET_STORE_STATE') {
        state.layoutData.currentDepartment = -1; // Reset department
        return appReducer({ 
            localization: state.localization, // Should not reset
            flow: undefined, 
            customerData: undefined,
            layoutData: state.layoutData, // Should not reset
            configData: state.configData, // Should not reset
            accountValueData: state.accountValueData, // Should not reset
            employeeData: state.employeeData // Should not reset
        }, action);
    }
  
    return appReducer(state, action);
};