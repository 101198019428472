import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import ClientConfig from '../../../config/clientConfig';
import { IApplicationState } from '../../../store';
import { resetStoreState, setCurrentPage } from '../../../store/Flow/actions';
import { IFlowState } from '../../../store/Flow/types';
import { LocalizationDictionary } from '../../../localization/localizationDictionary';

import './index.css';
import { ILayoutDataState } from '../../../store/LayoutData/types';
import { IConfigDataState } from '../../../store/ConfigData/types';
import { setCurrentDepartment } from '../../../store/LayoutData/actions';

interface ILogo {
    logo: any;
}

interface IState {
    buttonDisabled: boolean;
}

interface IDispatchProps {
    setCurrentPage: typeof setCurrentPage;
    resetStoreState: typeof resetStoreState;
    setCurrentDepartment: typeof setCurrentDepartment;
}

type Props = IDispatchProps & IFlowState & ILogo & ILayoutDataState & IConfigDataState;

class DepartmentPage extends React.Component<Props, IState> {
    public readonly state: IState = {
        buttonDisabled: true,
    }

    private readonly strings = LocalizationDictionary.getStrings;

    private nextButtonClicked = (index: number) => {
        const { setCurrentPage, setCurrentDepartment } = this.props;

        setCurrentDepartment(index);
        setCurrentPage("EmployeePage" as PageType);
    }

    private addButtons() {
        const { departmentsData, layoutData } = this.props

        // Get departments with image
        var validDepartments: any[] = [];
        for (let i = 0; i < departmentsData.length; i ++) {
            if (departmentsData[i].image && departmentsData[i].image.file && departmentsData[i].image.file !== "") {
                let validDepartment = { ...departmentsData[i], nextButtonClick: this.nextButtonClicked, styleButton: this.styleButton }
                validDepartments.push(validDepartment)
            }
        }

        // Split departments into rows
        let chunkSize = validDepartments.length > 8 ? 5 : 4;
        var chuncks: any[][] = [];
        for (let i = 0; i < validDepartments.length; i += chunkSize) {
            let chunk = validDepartments.slice(i, i + chunkSize);
            chuncks.push(chunk)
        }

        // Draw department buttons
        return (<div>
            {chuncks.map(function (chunk, cIndex) {
                return (<div className="rowDepartment" key={'Row' + cIndex}>
                    {chunk.map(function (item, iIndex) {
                        var arrayIndex = cIndex * chunkSize + iIndex;
                        return (<div className="columnDepartment" key={'Item' + arrayIndex}
                            style={item.styleButton(layoutData)} onClick={() => { item.nextButtonClick(arrayIndex)}}>
                            <img src={item.image.file} alt={'Department ' + arrayIndex} />
                        </div>)
                    })}
                </div>)
            })}
        </div>)
    }

    private styleButton(layoutData: CheckInLayoutDTO): React.CSSProperties {
        if (layoutData.code === "Dragsbaek") {
            return ({ position: 'relative', width: '22vh', height: '22vh' })
        } else if (layoutData.code === "Flensted" || layoutData.code === "FlenstedWorkshop") {
            return ({ position: 'relative', width: '160px', height: '160px' })
        } else {
            return ({ position: 'relative', width: '250px', height: '250px' })
        }
    }

    // TODO: Special logo case
    private addMainFieldsPageTitle() {
        const { logo, layoutData } = this.props

        if (layoutData.code === "OfficePartner") {
            return (<h2 style={{ position: 'absolute', top: '25%' }}>{this.strings().mainfieldsPageTitle}</h2>)
        } else if (layoutData.code === "Reisswolf") {
            return (<div>
                <img className="logo" src={logo} onClick={this.iconClick} alt="Logo" />
                <h2 className="Reisswolf-title" style={{ color: ClientConfig.mainColor }}>{this.strings().checkinreisswolf}</h2>
            </div>)
        }
    }

    private iconClick = () => {
        const { resetStoreState } = this.props;

        resetStoreState();
    }

    render() {
        const { currentPage } = this.props;

        if (currentPage === "DepartmentPage") {
            return (
                <div>
                    <div className="main-fields-container">
                        <div className="main-text-fields-container">
                            {this.addMainFieldsPageTitle()}
                        </div>
                        {this.addButtons()}
                    </div>
                </div>
            );
        }
        else {
            return (<div>Error</div>);
        }
    }
}

const mapStateToProps = (state: IApplicationState): Partial<IFlowState & ILayoutDataState & IConfigDataState> => {
    const { currentPage, currentFlow } = state.flow;
    const { layoutData, conditionData, departmentsData } = state.layoutData;
    const { configData } = state.configData;

    return {
        currentPage, currentFlow, layoutData, conditionData, configData, departmentsData
    };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
    return bindActionCreators(
        { setCurrentPage, resetStoreState, setCurrentDepartment },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DepartmentPage);
