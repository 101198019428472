import { ActionCreator } from 'redux';

import {
    EmployeeActionTypes as action,
    ISetEmployeesAction
} from './types';

export const setEmployees: ActionCreator<ISetEmployeesAction> = (
    employees: EmployeeDTO[],
) => ({
    type: action.SET_EMPLOYEES,
    payload: {
        employees,
    },
});
