import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import ClientConfig from '../../../config/clientConfig';
import { PrimaryButton } from '@fluentui/react'
import { IApplicationState } from '../../../store';
import { resetStoreState, setCurrentPage } from '../../../store/Flow/actions';
import { IFlowState } from '../../../store/Flow/types';
import { LocalizationDictionary } from '../../../localization/localizationDictionary';

import './index.css';
import { ILayoutDataState } from '../../../store/LayoutData/types';
import { IConfigDataState } from '../../../store/ConfigData/types';
import StylesUtil from '../../../utils/StylesUtil';
import { ILocalizationState } from '../../../store/Localization/types';
import { setCurrentDepartment } from '../../../store/LayoutData/actions';

interface ILogo {
    logo: any;
}

interface IState {
    checked: boolean;
}

interface IDispatchProps {
    setCurrentPage: typeof setCurrentPage;
    resetStoreState: typeof resetStoreState;
    setCurrentDepartment: typeof setCurrentDepartment;
}

type Props = IDispatchProps & IFlowState & ILogo & ILayoutDataState & IConfigDataState & ILocalizationState;

class ConditionPage extends React.Component<Props, IState> {
    public readonly state: IState = {
        checked: false,
    }

    private readonly strings = LocalizationDictionary.getStrings;

    private nextButtonClicked = () => {
        const { currentFlow, setCurrentPage, layoutData, conditionData, configData, departmentsData, language, setCurrentDepartment } = this.props;
        var currentCondition = conditionData.find(({ localizationCode }) => localizationCode === language);

        setCurrentPage("CustomFieldsPage" as PageType);

        if (currentCondition && currentCondition.usePdf && currentCondition.pdfURL !== null && currentCondition.pdfURL.trim() !== "") {
            setCurrentPage("ConditionPdfPage" as PageType)
        }
        else {
            switch (currentFlow) {
                case "CheckinAppointment":
                    if (layoutData.code === "EDForhandler" || layoutData.code === "Reisswolf") {
                        setCurrentPage("MainFieldsPage" as PageType)
                        break;
                    } else if (layoutData.code === "HIN") {
                        if (configData.useClientDepartments && departmentsData && departmentsData.length > 0) {
                            if (departmentsData.length === 1) {
                                setCurrentDepartment(0);
                                setCurrentPage("EmployeePage" as PageType);
                            } else {
                                setCurrentPage("DepartmentPage" as PageType);
                            }
                        } else {
                            setCurrentPage("EmployeePage" as PageType);
                        }
                        break;
                    } else {
                        setCurrentPage("PhoneNumberPage" as PageType)
                        break;
                    }
                case "CheckinNoAppointment":
                    if (layoutData.code === "EDForhandler" || layoutData.code === "Sef" || layoutData.code === "Seftest" || layoutData.code === "ErhvershusMidt" || layoutData.code === "HIN") {
                        if (currentCondition && currentCondition.useVideo && currentCondition.videoURL !== null && currentCondition.videoURL.trim() !== "") {
                            setCurrentPage("ConditionVideoPage" as PageType);
                            break;
                        } else {
                            setCurrentPage("MessagePage" as PageType);
                            break;
                        }
                    } else if (layoutData.code === "Kjaergaard") {
                        setCurrentPage("PhoneNumberPage" as PageType);
                        break;
                    } else if (layoutData.code === "Haugen") {
                        setCurrentPage("MessagePage" as PageType);
                        break;
                    }
                    break;
                case "Checkout":
                    if (layoutData.code === "Reisswolf") {

                        //Add new page to view currently checked in
                        setCurrentPage("CheckoutListPage" as PageType);
                        break;
                    } else {
                        setCurrentPage("PhoneNumberPage" as PageType);
                        break;
                    }
                case "Carrier":
                    setCurrentPage("MessagePage" as PageType);
                    break;
                case "HR":
                    setCurrentPage("MessagePage" as PageType);
                    break;
                case "Other":
                    setCurrentPage("MessagePage" as PageType);
                    break;
                case "CheckinNoInfo":
                    if (configData.useClientDepartments && departmentsData && departmentsData.length > 0) {
                        if (departmentsData.length === 1) {
                            setCurrentDepartment(0);
                            setCurrentPage("EmployeePage" as PageType);
                        } else {
                            setCurrentPage("DepartmentPage" as PageType);
                        }
                    } else {
                        setCurrentPage("EmployeePage" as PageType);
                    }
                    break;
                case "GroupCheckin":
                    setCurrentPage("PhoneNumberPage" as PageType)
                    break;
                case "LeverandoerCheckin":
                    setCurrentPage("MainFieldsPage" as PageType)
                    break;
                case "Event":
                    setCurrentPage("MainFieldsPage" as PageType)
                    break;
            }
        }
    }

    // TODO: Special logo case
    private addMainFieldsPageTitle() {
        const { logo, layoutData } = this.props

        if (layoutData.code === "OfficePartner") {
            return (<h2 style={{ position: 'absolute', top: '25%' }}>{this.strings().mainfieldsPageTitle}</h2>)
        } else if (layoutData.code === "Reisswolf") {
            return (<div>
                <img className="logo" src={logo} onClick={this.iconClick} alt="Reisswolf logo" />
                <h2 className="Reisswolf-title" style={{ color: ClientConfig.mainColor }}>{this.strings().checkinreisswolf}</h2>
            </div>)
        }
    }

    private isURL(text: string): boolean {
        var isURL = new RegExp("^(http://|https://|http://www.|https://www.|www.){1}[a-z0-9-]+[.]{1}[a-z0-9-]+")
        return isURL.test(text);
    }

    private addMessage() {
        const { conditionData, layoutData, language } = this.props;
        var currentCondition = conditionData.find(({ localizationCode }) => localizationCode === language);

        var text = currentCondition.condition.replace("\n", " ");
        var words = text.split(" ");

        if (layoutData.code === "Dragsbaek") {
            return (<div>
                <p className="PhoneFriendly-condition-message" style={({ color: ClientConfig.mainTextColor })}>
                    {words.map((word) => {
                        if (this.isURL(word)) {
                            return (<><a href={word} target="_blank" rel="noopener noreferrer">{word}</a></>)
                        } else {
                            return (word + ' ')
                        }
                    })}
                </p>
            </div>)
        } else if (layoutData.code === "LouisPoulsen") {
            return (<div>
                <p className="condition-message" style={({ color: ClientConfig.tertiaryTextColor })}>
                    {words.map((word) => {
                        if (this.isURL(word)) {
                            return (<><a href={word} target="_blank" rel="noopener noreferrer">{word}</a></>)
                        } else {
                            return (word + ' ')
                        }
                    })}
                </p>
            </div>)
        } else {
            return (<div>
                <p className="condition-message" style={({ color: ClientConfig.mainTextColor })}>
                    {words.map((word) => {
                        if (this.isURL(word)) {
                            return (<><a href={word} target="_blank" rel="noopener noreferrer">{word}</a></>)
                        } else {
                            return (word + ' ')
                        }
                    })}
                </p>
            </div>)
        }
    }

    private addCheckBox() {
        const { layoutData } = this.props;

        if (layoutData.code === "Dragsbaek") {
            return (<div className="check-box-and-message">
                <label className="PhoneFriendly-condition-check-box">
                    <input style={StylesUtil.getCheckMarkStyle(layoutData)}
                        type="checkbox"
                        checked={this.state.checked}
                        onChange={() => this.setState({ checked: !this.state.checked })}
                    />
                </label>
                <p className="PhoneFriendly-condition-message" style={({ color: ClientConfig.mainTextColor })}>{this.strings().acceptTemsAndConditions}</p>
            </div>)
        } else if (layoutData.code === "LouisPoulsen") {
            return (<div className="check-box-and-message">
                <label className="condition-check-box">
                    <input style={StylesUtil.getCheckMarkStyle(layoutData)}
                        type="checkbox"
                        checked={this.state.checked}
                        onChange={() => this.setState({ checked: !this.state.checked })}
                    />
                </label>
                <p className="condition-message" style={({ color: ClientConfig.tertiaryTextColor })}>{this.strings().acceptTemsAndConditions}</p>
            </div>)
        } else {
            return (<div className="check-box-and-message">
                <label className="condition-check-box">
                    <input style={StylesUtil.getCheckMarkStyle(layoutData)}
                        type="checkbox"
                        checked={this.state.checked}
                        onChange={() => this.setState({ checked: !this.state.checked })}
                    />
                </label>
                <p className="condition-message" style={({ color: ClientConfig.mainTextColor })}>{this.strings().acceptTemsAndConditions}</p>
            </div>)
        }
    }

    private addNextButton(buttonDisabled: boolean) {
        const { layoutData } = this.props

        if (layoutData.code === "Reisswolf") {
            return (
                <button className="condition-continue2-button" style={buttonDisabled ? { fontFamily: 'Inter', backgroundColor: 'rgb(243, 242, 241)', color: 'rgb(210, 208, 206)' } : { fontFamily: 'Inter', backgroundColor: ClientConfig.mainColor, color: ClientConfig.mainTextColor }} onClick={this.nextButtonClicked} disabled={buttonDisabled}>{this.strings().continue}</button>
            )
        } else {
            return (<div className="condition-main-fields-button-container">
                <PrimaryButton styles={StylesUtil.getButtonStyles(layoutData)} className="custom-fields-button" text={this.strings().next} onClick={this.nextButtonClicked} disabled={buttonDisabled} allowDisabledFocus />
            </div>);
        }
    }

    private iconClick = () => {
        const { resetStoreState } = this.props;

        resetStoreState();
    }

    render() {
        const { currentPage } = this.props;
        const { checked } = this.state;

        if (currentPage === "ConditionPage") {
            return (
                <div>
                    <div className="main-fields-container">
                        <div className="main-text-fields-container">
                            {this.addMainFieldsPageTitle()}
                            {this.addMessage()}
                        </div>
                        <div className="button-text-container">
                            {this.addCheckBox()}
                            {this.addNextButton(!checked)}
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return (<div>Error</div>);
        }
    }
}

const mapStateToProps = (state: IApplicationState): Partial<IFlowState & ILayoutDataState & IConfigDataState & ILocalizationState> => {
    const { currentPage, currentFlow } = state.flow;
    const { layoutData, conditionData, departmentsData } = state.layoutData;
    const { configData } = state.configData;
    const { language } = state.localization;

    return {
        currentPage, currentFlow, layoutData, conditionData, configData, departmentsData, language
    };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
    return bindActionCreators(
        { setCurrentPage, resetStoreState, setCurrentDepartment },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConditionPage);
