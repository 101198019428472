import { ActionCreator } from 'redux';

import {
    CustomerDataActionTypes as action,
    ISetCustomerDataAction,
    ISetCustomerCountAction,
    ISetCustomerPurposeAction,
} from './types';

export const setCustomerData: ActionCreator<ISetCustomerDataAction> = (
    data: CustomerDTO,
) => ({
    type: action.SET_CUSTOMER_DATA,
    payload: {
        data,
    },
});

export const setCustomerCount: ActionCreator<ISetCustomerCountAction> = (
    customerCount: number,
) => ({
    type: action.SET_CUSTOMER_COUNT,
    payload: {
        customerCount,
    },
});

export const setCustomerPurpose: ActionCreator<ISetCustomerPurposeAction> = (
    customerPurpose: string,
) => ({
    type: action.SET_CUSTOMER_PURPOSE,
    payload: {
        customerPurpose,
    },
});
