import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import ClientConfig from '../../../config/clientConfig';
import { PrimaryButton, TextField } from '@fluentui/react'
import { IApplicationState } from '../../../store';
import { setCurrentFlow, resetStoreState, setCurrentPage  } from '../../../store/Flow/actions';
import { IFlowState } from '../../../store/Flow/types';
import { LocalizationDictionary } from '../../../localization/localizationDictionary';
import { ICustomerDataState } from '../../../store/CustomerData/types';
import { setCustomerData } from '../../../store/CustomerData/action';
import { ILocalizationState } from '../../../store/Localization/types';
import Keyboard from "react-simple-keyboard";

import './index.css';
import { ILayoutDataState } from '../../../store/LayoutData/types';
import { IConfigDataState } from '../../../store/ConfigData/types';
import StylesUtil from '../../../utils/StylesUtil';
import { setCurrentDepartment } from '../../../store/LayoutData/actions';
import FlowCard from '../FrontPage/FlowCard';
import { dispatchRequest } from '../../../api/apiUtils';
import { finilizeFlowRequest, getEmployeeRequest, sendEmail, sendSMSRequest } from '../../../api/checkinApi';

interface ILogo {
    logo: any;
}

interface IState {
    firstName: string;
    secondName: string;
    buttonDisabled: boolean;
    nameToDisplay: string;
}

interface IDispatchProps {
    setCurrentPage: typeof setCurrentPage;
    setCustomerData: typeof setCustomerData;
    resetStoreState: typeof resetStoreState;
    setCurrentDepartment: typeof setCurrentDepartment;
    setCurrentFlow: typeof setCurrentFlow;
}

type Props = IDispatchProps & IFlowState & ICustomerDataState & ILocalizationState & ILogo & ILayoutDataState & IConfigDataState;

class CustomFlowcardPage extends React.Component<Props, IState> {
    public readonly state: IState = {
        firstName: '',
        secondName: '',
        buttonDisabled: true, 
        nameToDisplay: ''
    }

    private readonly strings = LocalizationDictionary.getStrings;
    private readonly keyboard: React.MutableRefObject<typeof Keyboard> = React.createRef();


    private customFlowCardClick = (type: FlowType) => {
        const { setCurrentPage, setCurrentFlow } = this.props;
        console.log("Button Clicked: " + type);
        setCurrentPage("EmployeePage" as PageType)
        setCurrentFlow(type)
    } 
    
    private customFlowcardContainer() {
        const { layoutData } = this.props;
        return (<div style={StylesUtil.getCustomFlowCardcontainerStyle(layoutData)}>
            <div><FlowCard type={"HotWork"} cardKey={"HotWork" as FlowType} click={this.customFlowCardClick} /></div>
            <div><FlowCard type={"NotHotWork"} cardKey={"NotHotWork" as FlowType} click={this.customFlowCardClick} /></div>
        </div>)
    }

    render() {
        const { currentPage } = this.props;
        
        if (currentPage === "CustomFlowcardPage") {
            return (
                <div>
                    {this.customFlowcardContainer()}
                </div>
            );
        } else {
            return (<div>Error</div>);
        }
    }
}

const mapStateToProps = (state: IApplicationState): Partial<IFlowState & ICustomerDataState & ILocalizationState & ILayoutDataState & IConfigDataState> => {
    const { currentPage, currentFlow } = state.flow;
    const { language } = state.localization;
    const { data } = state.customerData;
    const { layoutData, conditionData, departmentsData } = state.layoutData;
    const { configData, languageData } = state.configData;

    return {
        currentPage, currentFlow, data, language, layoutData, conditionData, configData, departmentsData, languageData
    };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
    return bindActionCreators(
        { setCurrentPage, setCustomerData, resetStoreState, setCurrentDepartment, setCurrentFlow },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomFlowcardPage);
