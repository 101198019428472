import UrlConfig from '../config/urlConfig';

export const checkClientExistsRequest = async (companyId: number, phoneNumber: string) => {
    const apiUrl = await UrlConfig.getApiUrl();

    const request: IRequest<any> = {
        url: apiUrl + `/api/CheckInCustomers/exists?PhoneNumber=${phoneNumber}&AccountId=${companyId}`
    };

    return request;
}

export const getClientByPhoneRequest = async (companyId: number, phoneNumber: string) => {
    const apiUrl = await UrlConfig.getApiUrl();

    const request: IRequest<any> = {
        url: apiUrl + `/api/CheckInCustomers/phone?PhoneNumber=${phoneNumber}&AccountId=${companyId}`
    };

    return request;
}

export const isCheckedOutRequest = async (companyId: number, phoneNumber: string) => {
    const apiUrl = await UrlConfig.getApiUrl();

    const request: IRequest<any> = {
        url: apiUrl + `/api/CheckInCustomers/checkedOut?PhoneNumber=${phoneNumber}&AccountId=${companyId}`
    };

    return request;
}

export const addClientRequest = async (companyId: number, client: CustomerDTO) => {
    client.companyId = companyId;

    const apiUrl = await UrlConfig.getApiUrl();

    const request: IRequest<any> = {
        url: apiUrl + `/api/CheckInCustomers/addFullCustomer`,
        body: client
    };

    return request;
}

export const getAttributesById = async (customerId: number) => {

    const apiUrl = await UrlConfig.getApiUrl();

    const request: IRequest<any> = {
        url: apiUrl + `/api/CheckInCustomers/getFullCustomer/${customerId}`
    };

    return request;
}