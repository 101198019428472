import { Reducer } from 'redux';
import {
    LayoutDataActions,
    LayoutDataActionTypes as actions,
    ILayoutDataState,
} from './types';

export const initialState: ILayoutDataState = {
    layoutData: { code: "", companyId: 0 },
    layoutsSharedHome: { config: new Map() },
    layoutsRedirect: { config: new Map() },
    conditionData: [],
    departmentsData: [],
    currentDepartment: -1,
};

const layoutDataReducer: Reducer<ILayoutDataState> = (
    state: ILayoutDataState = initialState,
    action: LayoutDataActions
) => {
    switch (action.type) {
        case actions.SET_LAYOUT_DATA:
            return { ...state, layoutData: action.payload.layoutData };
        case actions.SET_LAYOUTS_SHARED_HOME:
            return { ...state, layoutsSharedHome: action.payload.layoutsSharedHome };
        case actions.SET_LAYOUTS_REDIRECT:
            return { ...state, layoutsRedirect: action.payload.layoutsRedirect };
        case actions.SET_CONDITION_DATA:
            return { ...state, conditionData: action.payload.conditionData };
        case actions.SET_DEPARTMENT_DATA:
            return { ...state, departmentsData: action.payload.departmentsData };
        case actions.SET_CURRENT_DEPARTMENT:
            return { ...state, currentDepartment: action.payload.currentDepartment };
        default:
            return state;
    }
}

export default layoutDataReducer;
