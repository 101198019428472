import { Action } from 'redux';

export interface IFlowState {
    currentPage: PageType;
    currentFlow: FlowType | undefined;
    phoneNumberExists: boolean;
    responsibleEmployeeId: number | undefined;
    responsibleEmployeeName: string;
}

export enum FlowActionTypes {
    SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
    SET_CURRENT_FLOW = 'SET_CURRENT_FLOW',
    SET_PHONE_NUMBER_EXISTS = 'SET_PHONE_NUMBER_EXISTS',
    SET_RESPONSIBLE_EMPLOYEE = 'SET_RESPONSIBLE_EMPLOYEE',
    SET_RESPONSIBLE_EMPLOYEE_NAME = 'SET_RESPONSIBLE_EMPLOYEE_NAME',
    RESET_STORE_STATE = 'RESET_STORE_STATE'
}

export interface ISetCurrentPageAction extends Action {
    type: FlowActionTypes.SET_CURRENT_PAGE;
    payload: {
        page: PageType;
    };
}

export interface ISetCurrentFlowAction extends Action {
    type: FlowActionTypes.SET_CURRENT_FLOW;
    payload: {
        flow: FlowType;
    };
}

export interface ISetPhoneNumberExistsAction extends Action {
    type: FlowActionTypes.SET_PHONE_NUMBER_EXISTS;
    payload: {
        phoneNumberExists: boolean;
    };
}

export interface ISetResponsibleEmployeeAction extends Action {
    type: FlowActionTypes.SET_RESPONSIBLE_EMPLOYEE;
    payload: {
        id: number;
    };
}

export interface ISetResponsibleEmployeeNameAction extends Action {
    type: FlowActionTypes.SET_RESPONSIBLE_EMPLOYEE_NAME;
    payload: {
        name: string;
    };
}

export interface IResetStoreStateAction extends Action {
    type: FlowActionTypes.RESET_STORE_STATE;
}

export type FlowActions =
    | ISetCurrentPageAction
    | ISetCurrentFlowAction
    | ISetPhoneNumberExistsAction
    | ISetResponsibleEmployeeAction
    | ISetResponsibleEmployeeNameAction
    | IResetStoreStateAction
