import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import ClientConfig from '../../../config/clientConfig';
import { IApplicationState } from '../../../store';
import { resetStoreState, setCurrentPage } from '../../../store/Flow/actions';
import { IFlowState } from '../../../store/Flow/types';
import { LocalizationDictionary } from '../../../localization/localizationDictionary';
import { ICustomerDataState } from '../../../store/CustomerData/types';
import { setCustomerData } from '../../../store/CustomerData/action';
import { ILocalizationState } from '../../../store/Localization/types';
import './index.css';
import { getAppointmentsByDate } from '../../../api/appointmentApi';
import { dispatchRequest } from '../../../api/apiUtils';
import { getAttributesById } from '../../../api/clientApi';
import './index.css';
import { getCompanyGuidFromId } from '../../../api/companyApi';


interface ILogo {
    logo: any;
}

interface IAppointment {
    id: number;
    customerId: number;
    customerName: string;
    customerPhone: string;
    employeeId: number;
    employeeName: string;
    companyId: number;
    appointmentTime: string;
    checkInDate: string;
    checkOutDate: string;
    customdata: string;
}

interface IState {
    buttonDisabled: boolean;
    chosenCustomer: any;
    appointments: IAppointment[];
}


interface IDispatchProps {
    setCurrentPage: typeof setCurrentPage;
    setCustomerData: typeof setCustomerData;
    resetStoreState: typeof resetStoreState;

}

type Props = IDispatchProps & IFlowState & ICustomerDataState & ILocalizationState & ILogo;

class CheckoutListPage extends React.Component<Props, IState> {
    public readonly state: IState = {
        buttonDisabled: true,
        chosenCustomer: '',
        appointments: []
    }

    private readonly strings = LocalizationDictionary.getStrings;


    

    private nextButtonClicked = async () => {
        const { currentFlow, setCurrentPage, data, setCustomerData } = this.props;
        const { chosenCustomer } = this.state;

        //data.phoneNumber = chosenCustomer.customerPhone
        data.companyId = chosenCustomer.companyId
        //todo: chosen customer only have full name, not first and secound, split these up before continueing
        const firstname = chosenCustomer.customerName.split(' ')[0]
        const secondname = chosenCustomer.customerName.replace(firstname + ' ', '')
        const customerInfo = await dispatchRequest(await getAttributesById(chosenCustomer.customerId))

        data.phoneNumber = chosenCustomer.customerPhone;
        data.firstName = firstname;
        data.secondName = secondname;
        data.customerInfos = customerInfo;
        setCustomerData(data)

        switch (currentFlow) {
            case "Checkout":
                setCurrentPage('MessagePage' as PageType)
            break;
            default:
                console.log("only checkout implementet")
            break;

        }
    }

    private clickCheckoutList(appointment: any) {
        this.setState({ buttonDisabled: false, chosenCustomer: appointment })

        const appointmentUIelements = document.getElementsByClassName('appointment-row') as HTMLCollectionOf<HTMLElement>;

        for (let i = 0; i < appointmentUIelements.length; i++) {
            if (appointment.id === appointmentUIelements[i].id) {

                appointmentUIelements[i].style.backgroundColor = ClientConfig.mainColor
                appointmentUIelements[i].style.color = ClientConfig.mainTextColor
            } else {
                appointmentUIelements[i].style.backgroundColor = ClientConfig.secondaryColor
                appointmentUIelements[i].style.color = ClientConfig.mainColor
            }
        }
    }


    async componentDidMount() {
        var today = new Date()
        var endOfToday = new Date()
        endOfToday.setHours(endOfToday.getHours() + 24)

        const date = this.checkTime(today.getDate())
        const endDate = this.checkTime(endOfToday.getDate())
        const month = this.checkTime(today.getMonth() + 1)
        const year = today.getFullYear()

        const datesearchparameterStart = month + "-" + date + "-" + year
        const datesearchparameterEnd = month + "-" + endDate + "-" + year

        try {
            let companyId = await dispatchRequest(await getCompanyGuidFromId()); // TODO: Temporary untill we get validation
            var appointmentList: IAppointment[] = await dispatchRequest(await getAppointmentsByDate(companyId, datesearchparameterStart, datesearchparameterEnd))

            var i = 0
            while (i < appointmentList.length) {
                if (new Date(appointmentList[i].checkOutDate) <= new Date() && appointmentList[i].checkOutDate !== null) {
                    appointmentList.splice(i, 1);
                } else {
                    var customdata = await dispatchRequest(await getAttributesById(appointmentList[i].customerId))

                    if (customdata.length !== 0) {
                        appointmentList[i].customdata = customdata[0].attributeValue
                    } else {
                        appointmentList[i].customdata = ""
                    }
                    i++
                }
            }

            this.setState({ appointments: appointmentList })
        }
        catch {
            console.log("Failed to setup Checkout List page.")
        }
    }

    private checkTime(i: any) {
        if (i < 10) {
            i = "0" + i
        }  

        return i
    }

    private JsonDataDisplay(JsonData: any) {
        const DisplayData = JsonData.map(
            (appointment: any) => {
                return (
                    <tr className="appointment-row" id={appointment.id} style={{ color: ClientConfig.mainColor, backgroundColor: ClientConfig.mainTextColor }} onClick={() => this.clickCheckoutList(appointment)}>
                        <td className="column1">{appointment.customerName}</td>
                        <td className="column2">{appointment.customdata}</td>
                        <td className="column3">{appointment.checkInDate.substring(0, 10)}</td>
                        <td className="column4">{appointment.checkInDate.substring(11,19)}</td>
                    </tr>
                )
            }
        )

        return (
        <div className="appointments-Container">
            <table className="appointments-Table">
                <thead>
                    <tr className="appointment-rowheader" style={{ color: ClientConfig.mainTextColor, backgroundColor: ClientConfig.mainColor }}>
                        <th className="column1h">{this.strings().checkoutlistname}</th>
                        <th className="column2h">{this.strings().checkoutlistcompany}</th>
                        <th className="column3h">{this.strings().checkoutlistdate}</th>
                        <th className="column4h">{this.strings().checkoutlisttime}</th>
                    </tr>
                </thead>
                <tbody className="appointments-Tablebody">
                    {DisplayData}
                </tbody>
            </table>

        </div>
        )
    }

    private showConfirm() {
        document.getElementById("confirm2-container").style.visibility = 'visible'
    }

    private closeConfirm() {
        document.getElementById("confirm2-container").style.visibility = 'hidden'
    }

    private iconClick = () => {
        const { resetStoreState } = this.props;

        resetStoreState();
    }

    render() {
        const { currentPage, logo } = this.props;
        const { buttonDisabled, appointments, chosenCustomer } = this.state;


        if (currentPage === "CheckoutListPage") {
            // TODO: Special logo case
            return (
                <div className="checkoutlist-container">
                    <img className="logo" src={logo} onClick={this.iconClick} alt="Logo"/>
                    <div className="checkoutlist-title" style={{ color: ClientConfig.mainColor }}>{this.strings().checkudresswolf}</div>
                    <div className="checkoutlist-description" style={{ color: ClientConfig.mainColor }} >{this.strings().checkoutlist}</div>
                    <div className="custom-fields-button-container">
                        <button className="continue-button" style={buttonDisabled ? { fontFamily: 'Inter', backgroundColor: 'rgb(243, 242, 241)', color: 'rgb(210, 208, 206)' } : { fontFamily: 'Inter', backgroundColor: ClientConfig.mainColor, color: ClientConfig.mainTextColor }} onClick={this.showConfirm} disabled={buttonDisabled}>{this.strings().continue}</button>
                    </div>
                    {this.JsonDataDisplay(appointments)}

                    <div id="confirm2-container" className="confirm-container" style={{ color: ClientConfig.mainColor, backgroundColor: 'white' }}>
                        <img className="logo" src={logo} onClick={this.iconClick} alt="Logo"/>
                        <div className="confirm-Text" style={{ fontFamily: 'Inter' }}>{this.strings().confirmCheckout}</div>
                        <div className="confirm-name">{chosenCustomer.customerName}</div>
                        <div className="confirm-company" style={{ fontFamily: 'Inter' }}>{chosenCustomer.customdata}</div>
                        <button className="back-button" style={{ fontFamily: 'Inter', backgroundColor: 'white', color: ClientConfig.mainColor, border: '4px solid ' + ClientConfig.mainColor }} onClick={this.closeConfirm}>{this.strings().back}</button>
                        <button className="confirm-button" style={{ fontFamily: 'Inter', backgroundColor: ClientConfig.mainColor, color: ClientConfig.mainTextColor }} onClick={this.nextButtonClicked}>{this.strings().continue}</button>
                    </div>
                </div>
            );
        }
        else {
            return (<div>Error</div>);
        }

    }
}

const mapStateToProps = (state: IApplicationState): Partial<IFlowState & ICustomerDataState & ILocalizationState> => {
    const { currentPage, currentFlow } = state.flow;
    const { language } = state.localization;
    const { data } = state.customerData;

    return {
        currentPage, currentFlow, language, data
    };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
    return bindActionCreators(
        { setCurrentPage, setCustomerData, resetStoreState },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckoutListPage);
