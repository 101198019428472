import * as React from "react";
import ReactPlayer from "react-player";
import { OnProgressProps } from "react-player/base";
import { IApplicationState } from "../../../store";
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ILayoutDataState } from "../../../store/LayoutData/types";
import './index.css';

interface IState {
}

interface IDispatchProps {
}

interface IProps {
    onDone: () => void;
    videoId: string;
    videoUrl: string;
}

type Props = IDispatchProps & ILayoutDataState & IProps & IState;

class ConditionalVideo extends React.Component<Props, IState> {
    private timeAt: number = 0;

    render() {
        const { videoId, videoUrl, onDone } = this.props;

        const videoConfig = {
            attributes: {
                disablePictureInPicture: true,
                controlsList: 'nodownload noremoteplayback noplaybackrate'
            },
            playerVars: { controls: 0 }
        };

        return (
            <ReactPlayer
                id={videoId}
                width="100%"
                height="100%"
                url={videoUrl}
                controls={true}
                // @ts-ignore
                config={videoConfig}
                onProgress={((progressProps) => this.onProgressT(progressProps, videoId))}
                onSeek={((seconds) => this.onSeekT(seconds, videoId))}
                onEnded={onDone}
                muted={false}
                playing={true}
            />
        );
    }

    private onProgressT(progressProps: OnProgressProps, videoId: string) {
        var parent = (document.getElementById(videoId) as HTMLDivElement);
        if (parent === undefined) {
            return;
        }
        var videoPlayer = (parent.firstChild as HTMLVideoElement);
        if (parent === undefined) {
            return;
        }

        if (videoPlayer.currentTime >= this.timeAt) {
            this.timeAt = videoPlayer.currentTime;
        }
    }

    private onSeekT(seconds: number, videoId: string) {
        var parent = (document.getElementById(videoId) as HTMLDivElement);
        if (parent === undefined) {
            return;
        }
        var videoPlayer = (parent.firstChild as HTMLVideoElement);
        if (parent === undefined) {
            return;
        }

        if (videoPlayer.currentTime > this.timeAt) {
            videoPlayer.currentTime = this.timeAt;
        }
    }
}


const mapStateToProps = (state: IApplicationState): Partial<ILayoutDataState> => {
    return {
    };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
    return bindActionCreators(
        {},
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConditionalVideo);
