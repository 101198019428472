import { Action } from 'redux';

export interface ILayoutDataState {
    layoutData: CheckInLayoutDTO | undefined,
    layoutsSharedHome: CheckInSharedHomeDTO | undefined,
    layoutsRedirect: CheckInRedirectHomeDTO | undefined,
    conditionData: ConditionDTO[] | undefined,
    departmentsData: DepartmentDTO[] | undefined,
    currentDepartment: number | undefined,
}

export enum LayoutDataActionTypes {
    SET_LAYOUT_DATA = 'SET_LAYOUT_DATA',
    SET_LAYOUTS_SHARED_HOME = 'SET_LAYOUTS_SHARED_HOME',
    SET_LAYOUTS_REDIRECT = 'SET_LAYOUTS_REDIRECT',
    SET_CONDITION_DATA = 'SET_CONDITION_DATA',
    SET_DEPARTMENT_DATA = 'SET_DEPARTMENT_DATA',
    SET_CURRENT_DEPARTMENT = 'SET_CURRENT_DEPARTMENT',
}

export enum KeyboardLayoutDesign {
    SMALL,
    MEDIUM,
    BIG,
    NUMPAD
}

export interface ISetLayoutDataAction extends Action {
    type: LayoutDataActionTypes.SET_LAYOUT_DATA;
    payload: {
        layoutData: CheckInLayoutDTO;
    };
}

export interface ISetLayoutsSharedHomeAction extends Action {
    type: LayoutDataActionTypes.SET_LAYOUTS_SHARED_HOME;
    payload: {
        layoutsSharedHome: CheckInSharedHomeDTO;
    };
}

export interface ISetLayoutsRedirectAction extends Action {
    type: LayoutDataActionTypes.SET_LAYOUTS_REDIRECT;
    payload: {
        layoutsRedirect: CheckInRedirectHomeDTO;
    };
}

export interface ISetConditionDataAction extends Action {
    type: LayoutDataActionTypes.SET_CONDITION_DATA;
    payload: {
        conditionData: ConditionDTO[];
    };
}

export interface ISetDepartmentDataAction extends Action {
    type: LayoutDataActionTypes.SET_DEPARTMENT_DATA;
    payload: {
        departmentsData: DepartmentDTO[];
    };
}

export interface ISetCurrentDepartmentAction extends Action {
    type: LayoutDataActionTypes.SET_CURRENT_DEPARTMENT;
    payload: {
        currentDepartment: number;
    };
}

export type LayoutDataActions =
    | ISetLayoutDataAction
    | ISetLayoutsSharedHomeAction
    | ISetLayoutsRedirectAction
    | ISetConditionDataAction
    | ISetDepartmentDataAction
    | ISetCurrentDepartmentAction
