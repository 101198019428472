import { Reducer } from 'redux';
import {
    EmployeeActions,
    EmployeeActionTypes as actions,
    IEmployeeState,
} from './types';

export const initialState: IEmployeeState = {
    employees: []
};

const employeeReducer: Reducer<IEmployeeState> = (
    state: IEmployeeState = initialState,
    action: EmployeeActions
) => {
    switch (action.type) {
        case actions.SET_EMPLOYEES:
            return { ...state, employees: action.payload.employees };
        default:
            return state;
    }
}

export default employeeReducer;