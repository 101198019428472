import { PrimaryButton, SearchBox } from '@fluentui/react';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { IApplicationState } from '../../../store';
import { resetStoreState, setCurrentPage, setResponsibleEmployee, setResponsibleEmployeeName } from '../../../store/Flow/actions';
import { IFlowState } from '../../../store/Flow/types';
import EmployeeCard from './EmployeeCard';
import './index.css';
import ClientConfig from '../../../config/clientConfig';
import { ILocalizationState } from '../../../store/Localization/types';
import { LocalizationDictionary } from '../../../localization/localizationDictionary';
import EmployeeCardWithPhoto from './EmployeeCardWithPhoto';
import KeyboardWrapper from '../../LayoutElements/VirtualKeyboard/KeyboardWrapper';
import Keyboard from 'react-simple-keyboard/build/components/Keyboard';
import HomeButton from '../../LayoutElements/HomeButton/HomeButton';
import { ILayoutDataState, KeyboardLayoutDesign } from '../../../store/LayoutData/types';
import { ICustomerDataState } from '../../../store/CustomerData/types';
import StylesUtil from '../../../utils/StylesUtil';
import { IEmployeeState } from '../../../store/Employees/types';

interface ILogo {
    logo: any;
}

interface IState {
    employees: EmployeeDTO[];
    searchTerm?: string;
    buttonDisabled?: boolean;
    pickedEmployeeName?: string;
}

interface IDispatchProps {
    setCurrentPage: typeof setCurrentPage;
    setResponsibleEmployee: typeof setResponsibleEmployee;
    setResponsibleEmployeeName: typeof setResponsibleEmployeeName;
    resetStoreState: typeof resetStoreState;
}

type Props = IState & IDispatchProps & IFlowState & ILocalizationState & ILayoutDataState & ILogo & ICustomerDataState & IEmployeeState;

class EmployeePage extends React.Component<Props, IState> {
    private readonly strings = LocalizationDictionary.getStrings;
    private readonly keyboard: React.MutableRefObject<typeof Keyboard> = React.createRef();
    public scrollHeight = 0;

    constructor(props: Props | Readonly<Props>) {
        super(props)

        this.state = {
            employees: [],
            searchTerm: '',
            buttonDisabled: true
        }
    }


    //kan muligvis ændre til at lave et check på layout version 3, hvor den isedet for at øndre page, så highlighter den
    //her kan jeg så lave en ny click funtion på en ny knap som jeg kalder kontakt
    //bliver nød til at hente config så jeg kan se versionen og en secondarycolor som skal bruges (måske også en text color) som skal bruges i highlight
    //største udfordring bliver at finde ud af fordan jeg ændre på værdier så jeg highlighter den persom som klikkes på
    private employeeCardClick = (id: number, name: string) => {
        const { setResponsibleEmployee, setCurrentPage, setResponsibleEmployeeName, layoutData } = this.props;



        setResponsibleEmployee(id);
        setResponsibleEmployeeName(name);
        this.setState({ pickedEmployeeName: name });

        //this.highlightClickedEmployee(name);

        //console.log("layout version: " + ClientConfig.layoutVersion)


        if (layoutData.code === "Reisswolf") {
            this.showConfirm();
        } else if ((ClientConfig.layoutVersion === 'v3' && layoutData.code === "SociatyOfLife") || layoutData.code !== "Retograad") {

            this.highlightClickedEmployeeCard(name);
            this.setState({ buttonDisabled: false })

        } else {

            setCurrentPage('MessagePage' as PageType)
        }



    }

    private noStartHighlight = () => {
        //console.log("entering no hightstart");
        const { layoutData } = this.props

        try {

            var test;
            var employeeWithPhoto = false;
            var noPhoto = document.getElementsByClassName("employee-card-container") as HTMLCollectionOf<HTMLElement>
            var photo = document.getElementsByClassName("employee-card-container-with-photo") as HTMLCollectionOf<HTMLElement>

            if (noPhoto.length > 0) {

                //console.log("gets no image employees")
                test = document.getElementsByClassName("employee-card-container") as HTMLCollectionOf<HTMLElement>;

            } else if (photo.length > 0) {
                employeeWithPhoto = true;
                test = document.getElementsByClassName("employee-card-container-with-photo") as HTMLCollectionOf<HTMLElement>;
            }

            for (let i = 0; i < test.length; i++) {

                if (employeeWithPhoto === false) {

                    if (layoutData.code === "Lauritzen" || layoutData.code === "Bulkers") {
                        test[i].style.backgroundImage = `url(${"/images/TransparentButton.png"})`;
                        test[i].style.backgroundSize = '100% 100%';
                        test[i].style.backgroundColor = 'transparent';
                        test[i].style.color = ClientConfig.mainTextColor;
                    } else {
                        test[i].style.backgroundColor = ClientConfig.mainColor;
                        test[i].style.color = ClientConfig.mainTextColor;
                    }


                } else if (employeeWithPhoto === true) {

                    if (layoutData.code === "Lauritzen" || layoutData.code === "Bulkers") {
                        test[i].style.backgroundImage = `url(${"/images/TransparentButton.png"})`;
                        test[i].style.backgroundSize = '100% 100%';
                        test[i].style.backgroundColor = 'transparent';
                        test[i].style.color = ClientConfig.mainTextColor;
                    } else {
                        test[i].style.backgroundColor = ClientConfig.mainColor;
                        test[i].style.color = ClientConfig.mainTextColor;
                    }

                }
            }

        } catch (error) {
            //console.log(error);
        }

    }


    private highlightClickedEmployeeCard = (name: string) => {
        const { layoutData } = this.props

        if ((ClientConfig.layoutVersion === 'v3' && layoutData.code === "SociatyOfLife") || layoutData.code !== "Retograad") {
            var test;
            var employeeWithPhoto = false;
            var noPhoto = document.getElementsByClassName("employee-card-container") as HTMLCollectionOf<HTMLElement>
            var photo = document.getElementsByClassName("employee-card-container-with-photo") as HTMLCollectionOf<HTMLElement>

            if (noPhoto.length > 0) {
                test = document.getElementsByClassName("employee-card-container") as HTMLCollectionOf<HTMLElement>;

            } else if (photo.length > 0) {
                employeeWithPhoto = true;
                test = document.getElementsByClassName("employee-card-container-with-photo") as HTMLCollectionOf<HTMLElement>;
            }

            for (let i = 0; i < test.length; i++) {

                if (employeeWithPhoto === false) {

                    if (test[i].getElementsByClassName("employee-info-no-photo")[0].getElementsByClassName("employee-flow-card-text")[0].textContent === name) {
                        test[i].style.backgroundColor = "#64e053";
                        test[i].style.color = ClientConfig.mainTextColor;
                    } else {
                        if (layoutData.code === "Lauritzen" || layoutData.code === "Bulkers") {
                            test[i].style.backgroundImage = `url(${"/images/TransparentButton.png"})`;
                            test[i].style.backgroundSize = '100% 100%';
                            test[i].style.backgroundColor = 'transparent';
                            test[i].style.color = ClientConfig.mainTextColor;
                        } else {
                            test[i].style.backgroundColor = ClientConfig.mainColor;
                            test[i].style.color = ClientConfig.mainTextColor;
                        }
                    }

                } else if (employeeWithPhoto === true) {

                    if (test[i].getElementsByClassName("employee-info")[0].getElementsByClassName("employee-flow-card-text")[0].textContent === name) {

                        test[i].style.backgroundColor = "#64e053";
                        test[i].style.color = ClientConfig.mainTextColor;
                    } else {
                        if (layoutData.code === "Lauritzen" || layoutData.code === "Bulkers") {
                            test[i].style.backgroundImage = `url(${"/images/TransparentButton.png"})`;
                            test[i].style.backgroundSize = '100% 100%';
                            test[i].style.backgroundColor = 'transparent';
                            test[i].style.color = ClientConfig.mainTextColor;
                        } else {
                            test[i].style.backgroundColor = ClientConfig.mainColor;
                            test[i].style.color = ClientConfig.mainTextColor;
                        }
                    }
                }
            }
        }


    }

    private contactButtonClick = () => {
        const { setCurrentPage, conditionData, language } = this.props;
        var currentCondition = conditionData.find(({ localizationCode }) => localizationCode === language);

        if (currentCondition && currentCondition.useVideo && currentCondition.videoURL !== null && currentCondition.videoURL.trim() !== "") {
            setCurrentPage("ConditionVideoPage" as PageType);
        } else {
            setCurrentPage("MessagePage" as PageType);
        }
    }

    private renderEmployeeCards = (): JSX.Element[] => {
        const { employees } = this.state;
        const { layoutData } = this.props

        if (layoutData.code === "Fundamentet") {

        } else {
            return employees.map(e =>
            (ClientConfig.employeePhotoEnabled ? <EmployeeCardWithPhoto employeeImage={e.image} id={e.id} key={e.id} employeeKey={e.id} employee={e} click={this.employeeCardClick} />
                : <EmployeeCard key={e.id} employeeKey={e.id} employee={e} click={this.employeeCardClick} />)
            )
        }
    }

    private textContactbutton() {
        const { layoutData } = this.props

        if (layoutData.code === "SociatyOfLife" && ClientConfig.layoutVersion === 'v3') {
            return "contact"
        } else {
            return this.strings().contactButton;
        }
    }

    private renderContactbutton = (disabled: boolean): JSX.Element => {
        const { layoutData } = this.props

        if (layoutData.code === "Reisswolf") {

        } else if ((ClientConfig.layoutVersion === 'v3' && layoutData.code === "SociatyOfLife") || (layoutData.code !== "Retograad" && layoutData.code !== "Fundamentet")) {
            return (<PrimaryButton style={this.addStyleButton()} styles={StylesUtil.getButtonStyles(layoutData)} className="main-fields-button" text={this.textContactbutton()} onClick={this.contactButtonClick} disabled={disabled} allowDisabledFocus />)
        }
    }

    private showConfirm() {
        document.getElementById("confirm-container").style.visibility = 'visible'
    }

    private closeConfirm() {
        document.getElementById("confirm-container").style.visibility = 'hidden'
    }

    private iconClick = () => {
        const { resetStoreState } = this.props;

        resetStoreState();
    }

    private addStyleButton(): any {
        const { layoutData } = this.props

        if (layoutData.code === "SociatyOfLife") {
            return ({ position: 'absolute', top: 200, left: 1400 })
        } else if (layoutData.code === "EDForhandler") {
            return ({ position: 'absolute', top: '0%', left: '93%' })
        } else if (layoutData.code === "OfficePartner") {
            return ({ position: 'absolute', top: '22.5%', left: '83%' })
        } else if (layoutData.code === "BirchEjendomme") {
            return ({ marginBottom: '70px' })
        } else if (layoutData.code === 'TotalEnergies') {
            return ({ marginBottom: '60px' })
        } else {
            return ({ marginBottom: '50px' })
        }

    }

    private getEmployeesFromDepartment(search: string): EmployeeDTO[] {
        const { departmentsData, currentDepartment } = this.props
        var employees: EmployeeDTO[] = [];

        if (currentDepartment < 0) {
            return employees;
        }

        search = search.toLowerCase();
        departmentsData[currentDepartment].clients.forEach((item) => {
            if (search === "" || item.name.toLowerCase().includes(search)) {
                employees.push(
                    {
                        id: item.id ?? 0,
                        firstName: item.name.substring(0, item.name.indexOf(' ')) ?? "",
                        secondName: item.name.substring(item.name.indexOf(' ') + 1) ?? "",
                        department: item.department ?? "",
                        jobTitle: item.jobTitle ?? "",
                        phoneNumber: item.phoneNumber ?? "",
                        imageId: item.image?.id ?? 0,
                        image: item.image?.file ?? "",
                        emailNotification: item.emailNotification ?? false,
                        smsNotification: item.smsNotification ?? false
                    }
                );
            }
        });
        return employees;
    }

    private getBySearchRequest = async (search: string) => {
        const { employees } = this.props
        try {
            var filteredEmployees: EmployeeDTO[] = [];

            search = search.toLowerCase();
            employees.forEach((item) => {
                if (search === "" ||
                    (item.firstName + " " + item.secondName).normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(search)) {
                    filteredEmployees.push(item);
                }
            });
            return this.sortEmployeesByRelevance(search, filteredEmployees);
        }
        catch (error) {
            console.log("Issue searching for employees", error);
            return [];
        }
    }

    private sortEmployeesByRelevance(searchWord: string, employees: EmployeeDTO[]): EmployeeDTO[] {
        var sortedList: EmployeeDTO[] = [];
        var firstNameNotRelevant: EmployeeDTO[] = [];
        employees.forEach(employee => {
            if (employee.firstName.toLowerCase().includes(searchWord.toLowerCase()))
                sortedList.push(employee)
            else
                firstNameNotRelevant.push(employee)
        })
        sortedList.push(...firstNameNotRelevant)
        return sortedList
    }

    private onSearchChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newName?: string) => {
        const { layoutData } = this.props
        this.searchEmployees(newName);

        var temp;
        if (layoutData.code === "Dragsbaek") {
            temp = document.getElementsByClassName('PhoneFriendly-employee-cards-container');
        } else {
            (this.keyboard.current as any).setInput(newName);
            temp = document.getElementsByClassName('employee-cards-container');
        }
        temp[0].scrollTop = 0;

    }

    componentDidMount = () => {
        const { layoutData, currentDepartment, departmentsData, employees } = this.props

        if (layoutData.code === "SSIDiagnostica") {
            this.setState({ employees: [] }) // Hide employees
        } else if (currentDepartment >= 0 && departmentsData[currentDepartment].clients.length > 0) {
            this.setState({ employees: this.getEmployeesFromDepartment("") })
        } else {
            this.setState({ employees: employees })
        }

        if (layoutData.code === "Fundamentet") {
            var homebtn = document.getElementsByClassName("home-button-container") as HTMLCollectionOf<HTMLElement>

            homebtn[0].style.bottom = '0px'
            homebtn[0].style.left = '0px'

        }

    }

    searchEmployees = async (search: string) => {
        const { layoutData, currentDepartment, departmentsData } = this.props

        var employees: EmployeeDTO[] = [];
        if (currentDepartment >= 0 && departmentsData[currentDepartment].clients.length > 0) {
            employees = this.getEmployeesFromDepartment(search);
        } else {
            employees = await this.getBySearchRequest(search);
        }

        if ((search == null || search.trim() === "") && layoutData.code === "SSIDiagnostica") {
            employees = [] // Hide employees on empty search
        }

        this.setState({ employees, searchTerm: search, buttonDisabled: true })


        if ((ClientConfig.layoutVersion === 'v3' && layoutData.code === "SociatyOfLife") || layoutData.code !== "Retograad") {
            this.noStartHighlight();
        }

    }

    private addKeyboard() {
        const { layoutData } = this.props

        if (layoutData.code === "SociatyOfLife" && ClientConfig.layoutVersion === 'v3') {

            return (<div style={{ position: 'absolute', top: 640, width: 1500, height: 500 }}><KeyboardWrapper keyboardRef={this.keyboard} onChange={this.searchEmployees} input={this.state.searchTerm} /></div>)
        } else if (layoutData.code === "Retograad" || layoutData.code === "Fundamentet" || layoutData.code === "Dragsbaek") {

        } else if (ClientConfig.layoutVersion === 'v3') {
            return (<div style={{ position: 'absolute', top: 640, width: 1500, height: 500 }}><KeyboardWrapper keyboardRef={this.keyboard} onChange={this.searchEmployees} input={this.state.searchTerm} /></div>)
        } else if (layoutData.code === "OfficePartner") {
            return (<div style={{ width: 1010, position: 'absolute', left: 35, top: '50%' }}> <KeyboardWrapper keyboardRef={this.keyboard} onChange={this.searchEmployees} input={this.state.searchTerm} /></div>);
        } else if (ClientConfig.employeePhotoEnabled === false && layoutData.code === "EDForhandler") {
            return (<div style={{ position: 'absolute', top: '57%', width: '100%' }}><KeyboardWrapper keyboardRef={this.keyboard} onChange={this.searchEmployees} input={this.state.searchTerm} /></div>)
        } else if (layoutData.code === "EDForhandler") {
            return (<div style={{ position: 'absolute', top: '86%', width: '100%', height: '150%', overflow: 'hidden' }}><KeyboardWrapper keyboardRef={this.keyboard} onChange={this.searchEmployees} small={false} input={this.state.searchTerm} /></div>);
        } else if (layoutData.code === "BirchEjendomme") { // Måske ny Default??
            return (<div style={{ width: '100%' }} > <KeyboardWrapper keyboardRef={this.keyboard} onChange={this.searchEmployees} small input={this.state.searchTerm} /></div>);
        } else if (layoutData.code === "TotalEnergies") {
            return (<KeyboardWrapper keyboardRef={this.keyboard} onChange={this.searchEmployees} small={false} layoutDesign={KeyboardLayoutDesign.BIG} input={this.state.searchTerm} />)
        } else if (ClientConfig.employeePhotoEnabled === false) {
            return (<div style={{ position: 'absolute', top: 640, width: 1500, height: 500 }}><KeyboardWrapper keyboardRef={this.keyboard} onChange={this.searchEmployees} input={this.state.searchTerm} /></div>)
        } else if (layoutData.code === "Reisswolf") {
            return (<div style={{ marginTop: 200 }}> <KeyboardWrapper keyboardRef={this.keyboard} onChange={this.searchEmployees} input={this.state.searchTerm} /></div>);
        } else {
            return (<div style={{ position: 'absolute', top: 740, width: 1500, height: 500 }}><KeyboardWrapper keyboardRef={this.keyboard} onChange={this.searchEmployees} small={false} medium={true} input={this.state.searchTerm} /></div>)
        }
    }

    private addSearchBox(searchTerm: any) {
        const { layoutData } = this.props

        if (layoutData.code === "Retograad" || layoutData.code === "Fundamentet") {

        } else if (ClientConfig.layoutVersion === 'v3') {
            return (<SearchBox styles={StylesUtil.getSearchBoxStyles(layoutData)} className={`search-${ClientConfig.layoutVersion}`} placeholder="Name" value={searchTerm} onChange={this.onSearchChange} />)

        } else if (layoutData.code === "OfficePartner") {
            return (<SearchBox styles={StylesUtil.getSearchBoxStyles(layoutData)} className={`search-${ClientConfig.layoutVersion}`} placeholder={this.strings().searchPlaceholder} value={searchTerm} onChange={this.onSearchChange}
                underlined={ClientConfig.layoutVersion === 'v1' ? false : true}
            />)

        } else if (layoutData.code === "EDForhandler") {
            return (<SearchBox styles={StylesUtil.getSearchBoxStyles(layoutData)} className={`search-${ClientConfig.layoutVersion}`} placeholder={this.strings().searchPlaceholder} value={searchTerm} onChange={this.onSearchChange}
                underlined={ClientConfig.layoutVersion === 'v1' ? false : true}
            />)

        } else if (layoutData.code === "TotalEnergies"){
            return (<SearchBox styles={StylesUtil.getSearchBoxStyles(layoutData)} className={`search-${ClientConfig.layoutVersion}`} placeholder={this.strings().searchHostPlaceholder} value={searchTerm} onChange={this.onSearchChange}
                underlined={ClientConfig.layoutVersion === 'v1' ? false : true}
            />)
        }else {
            return (<SearchBox styles={StylesUtil.getSearchBoxStyles(layoutData)} className={`search-${ClientConfig.layoutVersion}`} placeholder={this.strings().searchPlaceholder} value={searchTerm} onChange={this.onSearchChange}
                underlined={ClientConfig.layoutVersion === 'v1' ? false : true}
            />)
        }
    }



    private clickScrollLeft(scrollHeight: number) {
        const { layoutData } = this.props

        var temp;
        if (layoutData.code === "Dragsbaek") {
            temp = document.getElementsByClassName('PhoneFriendly-employee-cards-container');
        } else {
            temp = document.getElementsByClassName('employee-cards-container');
        }
        //var temp2 = document.getElementsByClassName('employee-card-container-with-photo employee-v3')
        //if (temp2.length / 5 > 1)
        //console.log("count: " + temp[0].scrollTop)
        var ey = temp[0].scrollTop
        //console.log(ey)
        temp[0].scrollTop = ey - scrollHeight;
    }

    private clickScrollRight(scrollHeight: number) {
        const { layoutData } = this.props

        var temp;
        if (layoutData.code === "Dragsbaek") {
            temp = document.getElementsByClassName('PhoneFriendly-employee-cards-container');
        } else {
            temp = document.getElementsByClassName('employee-cards-container');
        }

        //console.log("count: " + temp[0].scrollTop)
        var ey = temp[0].scrollTop
        //console.log(ey)
        temp[0].scrollTop = ey + scrollHeight;
    }

    private clickScrollLeftPhoto(scrollHeight: number) {
        const { layoutData } = this.props

        var temp;
        if (layoutData.code === "Dragsbaek") {
            temp = document.getElementsByClassName('PhoneFriendly-employee-cards-container');
        } else {
            temp = document.getElementsByClassName('employee-cards-container');
        }
        //var temp2 = document.getElementsByClassName('employee-card-container-with-photo employee-v3')
        //if (temp2.length / 5 > 1)
        //console.log("count: " + temp[0])

        var ey = temp[0].scrollTop
        //console.log(ey)
        temp[0].scrollTop = ey - scrollHeight;
    }

    private clickScrollRightPhoto(scrollHeight: number) {
        const { layoutData } = this.props

        var temp;
        if (layoutData.code === "Dragsbaek") {
            temp = document.getElementsByClassName('PhoneFriendly-employee-cards-container');
        } else {
            temp = document.getElementsByClassName('employee-cards-container');
        }
        //console.log("count: " + temp[0])

        var ey = temp[0].scrollTop
        //console.log(ey)
        temp[0].scrollTop = ey + scrollHeight;
    }

    private addScrollButtons() {
        const { layoutData } = this.props

        var pixelScroll = 0;
        if (layoutData.code === "Dragsbaek") {
            if (ClientConfig.employeePhotoEnabled) {
                pixelScroll = window.innerHeight * 0.54;
                return (<div>
                    <PrimaryButton style={StylesUtil.styleScrollleftBtn(layoutData)} className='leftScrollButton' text='◄' onClick={() => this.clickScrollLeftPhoto(pixelScroll)} />
                    <PrimaryButton style={StylesUtil.styleScrollrightBtn(layoutData)} className='rightScrollButton' text='►' onClick={() => this.clickScrollRightPhoto(pixelScroll)} />
                </div>)
            } else {
                pixelScroll = window.innerHeight * 0.345;
                return (<div>
                    <PrimaryButton style={StylesUtil.styleScrollleftBtn(layoutData)} className='leftScrollButton' text='◄' onClick={() => this.clickScrollLeft(pixelScroll)} />
                    <PrimaryButton style={StylesUtil.styleScrollrightBtn(layoutData)} className='rightScrollButton' text='►' onClick={() => this.clickScrollRight(pixelScroll)} />
                </div>)
            }
        } else if (ClientConfig.layoutVersion === 'v3' && layoutData.code !== "Retograad") {

            return (<div>
                <PrimaryButton style={{ position: 'absolute', top: 430, left: 210, backgroundColor: 'white', color: '#707070', width: 100, height: 100, fontSize: 60, borderColor: 'white' }} className='leftScrollButton' text='◄' onClick={() => this.clickScrollLeft(300)} />
                <PrimaryButton style={{ position: 'absolute', top: 430, right: 210, backgroundColor: 'white', color: '#707070', width: 100, height: 100, fontSize: 60, borderColor: 'white' }} className='rightScrollButton' text='►' onClick={() => this.clickScrollRight(300)} />
            </div>)
        } else if (ClientConfig.layoutVersion !== 'v3' && ClientConfig.employeePhotoEnabled === false && layoutData.code !== "Fundamentet") {
            if (layoutData.code === "BirchEjendomme") {
                return (<div style={{ height: '32px'}}>
                    <PrimaryButton style={StylesUtil.styleScrollleftBtn(layoutData)} className='leftScrollButton' text='◄' onClick={() => this.clickScrollLeft(240)} />
                    <PrimaryButton style={StylesUtil.styleScrollrightBtn(layoutData)} className='rightScrollButton' text='►' onClick={() => this.clickScrollRight(240)} />
                </div>)
            } else if (layoutData.code === "TotalEnergies") {
                return (
                    <div style={{ width: '100%', height: '100%', position: 'absolute', pointerEvents: 'none' }}>
                        <PrimaryButton style={StylesUtil.styleScrollleftBtn(layoutData)} className='leftScrollButton' text='◄' onClick={() => this.clickScrollLeft(260)} />
                        <PrimaryButton style={StylesUtil.styleScrollrightBtn(layoutData)} className='rightScrollButton' text='►' onClick={() => this.clickScrollRight(260)} />
                    </div>
                )
            }
            return (<div>
                <PrimaryButton style={StylesUtil.styleScrollleftBtn(layoutData)} className='leftScrollButton' text='◄' onClick={() => this.clickScrollLeft(260)} />
                <PrimaryButton style={StylesUtil.styleScrollrightBtn(layoutData)} className='rightScrollButton' text='►' onClick={() => this.clickScrollRight(260)} />
            </div>)
        } else if (ClientConfig.layoutVersion !== 'v3' && ClientConfig.employeePhotoEnabled === true && layoutData.code !== "Fundamentet") {
            return (<div>
                <PrimaryButton style={StylesUtil.styleScrollleftBtn(layoutData)} className='leftScrollButton' text='◄' onClick={() => this.clickScrollLeftPhoto(410)} />
                <PrimaryButton style={StylesUtil.styleScrollrightBtn(layoutData)} className='rightScrollButton' text='►' onClick={() => this.clickScrollRightPhoto(410)} />
            </div>)
        }
    }

    private addTitle() {
        const { layoutData, logo } = this.props

        if (layoutData.code === "Retograad") {
            return (<h2 style={{ color: ClientConfig.mainTextColor, top: '500px', position: 'absolute', fontSize: '50px' }}>Med aftale</h2>)
        } else if (layoutData.code === "Fundamentet") {
            const currentYear: number = new Date().getFullYear();
            const yearString: string = currentYear.toString();
            return (<h1 style={{ color: ClientConfig.mainTextColor, top: '30%', position: 'absolute', fontSize: '30px', fontWeight: 'bold', minWidth: 1300, textAlign: 'center' }}>Er det første gang du i {yearString} besøger Fundamentet?</h1>) // Tilføj så den henter dette års år
        } else if (layoutData.code === "Reisswolf") {
            // TODO: Special logo case
            return (<div>
                <img className="logo" src={logo} onClick={this.iconClick} alt="Logo" />
                <h2 className="Reisswolf-title" style={{ color: ClientConfig.mainColor }}>{this.strings().checkinreisswolf}</h2>
                <h2 className="Reisswolf-secondtitle" style={{ color: ClientConfig.mainColor, fontFamily: "Inter" }}>{this.strings().reisswolftCheckinFollowtitle}</h2>
            </div>)
        }
    }

    private returnClick = () => {
        const { setCurrentPage } = this.props;

        setCurrentPage("FrontPage" as PageType);
    }

    private returnStyle(): React.CSSProperties {
        return {
            fontSize: "28px",
            height: "55px",
            width: "160px",
            color: ClientConfig.secondaryTextColor,
            backgroundColor: ClientConfig.mainColor,
            position: 'absolute',
            top: 880,
            textAlign: 'center',
            paddingTop: '5px',
            fontWeight: 'bold'
        }
    }

    private addReturn() {
        const { layoutData } = this.props

        if (layoutData.code === "Retograad") {
            return (<div style={this.returnStyle()} onClick={this.returnClick}>Tilbage</div>)
        } else if (layoutData.code === "Fundamentet") {
            return (<div style={StylesUtil.getHomeContainerStyle(layoutData)} className="home-button-container">
                <HomeButton />
            </div>)
        }
    }

    private clickFundamentetButton(choice: string) {
        const { setResponsibleEmployee, setCurrentPage, setResponsibleEmployeeName } = this.props;
        const { employees } = this.state;

        setResponsibleEmployee(employees[0].id);
        setResponsibleEmployeeName(choice);
        this.setState({ pickedEmployeeName: choice });

        setCurrentPage('MessagePage' as PageType)
    }

    private styleFundamentetButtons(): React.CSSProperties {
        return ({
            fontSize: '20px',
            fontWeight: 'bold',
            backgroundColor: ClientConfig.mainColor,
            color: ClientConfig.mainTextColor,
            width: 300,
            margin: 15,
            height: 160,
            display: 'inline-block',
            textAlign: 'center',
            padding: 20,
            paddingTop: '9.5%',
            border: '1px solid black',
            borderRadius: 10,
        })
    }

    private addFundamentetButton() {
        const { layoutData } = this.props

        if (layoutData.code === "Fundamentet") {
            return (<div style={{ marginTop: 90 }}>
                <div style={this.styleFundamentetButtons()} onClick={() => this.clickFundamentetButton(this.strings().ja)}>{this.strings().ja}</div>
                <div style={this.styleFundamentetButtons()} onClick={() => this.clickFundamentetButton(this.strings().nej)}>{this.strings().nej}</div>
            </div>)
        }
    }

    //style add to searchbox 
    render() {
        const { searchTerm, buttonDisabled, pickedEmployeeName } = this.state
        const { currentPage, logo, data, layoutData, currentFlow } = this.props;
        //console.log(currentFlow);

        var customAtrributeValue = ""
        if (data.customerInfos && data.customerInfos.length > 0) {
            customAtrributeValue = data.customerInfos[0].attributeValue
        }

        if (currentPage === "EmployeePage") {
            if (layoutData.code === "Dragsbaek") {
                // TODO: Special logo case
                return (
                    <div style={StylesUtil.getEmployeePageStyle(layoutData)}>
                        {this.addSearchBox(searchTerm)}
                        <div className="main-fields-button-container" style={{ height: '0%' }}>
                            {this.renderContactbutton(buttonDisabled)}
                        </div>

                        <div className="PhoneFriendly-employee-cards-container" style={StylesUtil.getEmployeeContainerStyle(layoutData, ClientConfig.employeePhotoEnabled)}>
                            {this.renderEmployeeCards()}
                            {this.addFundamentetButton()}
                        </div>
                        {this.addTitle()}
                        {this.addScrollButtons()}
                        {this.addKeyboard()}
                        {this.addReturn()}

                        <div id="confirm-container" className="confirm-container" style={{ color: ClientConfig.mainColor, backgroundColor: 'white' }}>
                            <img className="logo" src={logo} onClick={this.iconClick} alt="Logo" />
                            <div className="confirm-Text" style={{ fontFamily: 'Inter' }}>{this.strings().confirmCheckin}</div>
                            <div className="confirm-name">{data.firstName + " " + data.secondName}</div>
                            {customAtrributeValue && <div className="confirm-company" style={{ fontFamily: 'Inter' }}>{customAtrributeValue}</div>}
                            <div className="confirm-company" style={{ fontFamily: 'Inter' }}>{pickedEmployeeName + this.strings().reisswolftCheckinFollowemployee}</div>
                            <button className="back-button" style={{ fontFamily: 'Inter', backgroundColor: 'white', color: ClientConfig.mainColor, border: '4px solid ' + ClientConfig.mainColor }} onClick={this.closeConfirm}>{this.strings().back}</button>
                            <button className="confirm-button" style={{ fontFamily: 'Inter', backgroundColor: ClientConfig.mainColor, color: ClientConfig.mainTextColor }} onClick={this.contactButtonClick}>{this.strings().continue}</button>
                        </div>
                    </div>
                );
            } else {
                // TODO: Special logo case
                return (
                    <div style={StylesUtil.getEmployeePageStyle(layoutData)}>
                        {this.addSearchBox(searchTerm)}
                        <div className="main-fields-button-container" style={{ height: '0%' }}>
                            {this.renderContactbutton(buttonDisabled)}
                        </div>

                        <div className="employee-cards-container" style={StylesUtil.getEmployeeContainerStyle(layoutData, ClientConfig.employeePhotoEnabled)}>
                            {this.renderEmployeeCards()}
                            {this.addFundamentetButton()}
                        </div>
                        {this.addTitle()}
                        {this.addScrollButtons()}
                        {this.addKeyboard()}
                        {this.addReturn()}
                        <div id="confirm-container" className="confirm-container" style={{ color: ClientConfig.mainColor, backgroundColor: 'white' }}>
                            <img className="logo" src={logo} onClick={this.iconClick} alt="Logo" />
                            <div className="confirm-Text" style={{ fontFamily: 'Inter' }}>{this.strings().confirmCheckin}</div>
                            <div className="confirm-name">{data.firstName + " " + data.secondName}</div>
                            {customAtrributeValue && <div className="confirm-company" style={{ fontFamily: 'Inter' }}>{customAtrributeValue}</div>}
                            <div className="confirm-company" style={{ fontFamily: 'Inter' }}>{pickedEmployeeName + this.strings().reisswolftCheckinFollowemployee}</div>
                            <button className="back-button" style={{ fontFamily: 'Inter', backgroundColor: 'white', color: ClientConfig.mainColor, border: '4px solid ' + ClientConfig.mainColor }} onClick={this.closeConfirm}>{this.strings().back}</button>
                            <button className="confirm-button" style={{ fontFamily: 'Inter', backgroundColor: ClientConfig.mainColor, color: ClientConfig.mainTextColor }} onClick={this.contactButtonClick}>{this.strings().continue}</button>
                        </div>
                    </div>
                );
            }
        }
        else {
            return (<div>Error</div>);
        }

    }
}

const mapStateToProps = (state: IApplicationState): Partial<IFlowState & ILocalizationState & ICustomerDataState & ILayoutDataState & IEmployeeState> => {
    const { currentPage } = state.flow;
    const { language } = state.localization
    const { layoutData, conditionData, currentDepartment, departmentsData } = state.layoutData
    const { data } = state.customerData;
    const { employees } = state.employeeData

    return {
        currentPage, language, layoutData, data, conditionData, currentDepartment, departmentsData, employees
    };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
    return bindActionCreators(
        { setCurrentPage, setResponsibleEmployee, setResponsibleEmployeeName, resetStoreState },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EmployeePage);
