import { Action } from 'redux';

export interface ICustomerDataState {
    data: CustomerDTO | undefined;
    customerCount: number | undefined;
    customerPurpose: string;
}

export enum CustomerDataActionTypes {
    SET_CUSTOMER_DATA = 'SET_CUSTOMER_DATA',
    SET_CUSTOMER_COUNT = 'SET_CUSTOMER_COUNT',
    SET_CUSTOMER_PURPOSE = 'SET_CUSTOMER_PURPOSE',
}

export interface ISetCustomerDataAction extends Action {
    type: CustomerDataActionTypes.SET_CUSTOMER_DATA;
    payload: {
        data: CustomerDTO;
    };
}

export interface ISetCustomerCountAction extends Action {
    type: CustomerDataActionTypes.SET_CUSTOMER_COUNT;
    payload: {
        customerCount: number;
    };
}

export interface ISetCustomerPurposeAction extends Action {
    type: CustomerDataActionTypes.SET_CUSTOMER_PURPOSE;
    payload: {
        customerPurpose: string;
    };
}

export type CustomerDataActions =
    | ISetCustomerDataAction
    | ISetCustomerCountAction
    | ISetCustomerPurposeAction
